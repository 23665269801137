import React from "react";

const RegistroAprobado = () => {
  return (
    <div
      centered
      size="lg"
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <div className="d-flex flex-column border-0 align-items-center">
        <img
          style={{ width: "90%", height: "auto" }}
          src="/assets/images/plataforma/aprobado.svg"
          alt="Imagen de éxito"
        />
        <h1 className="fw-bold fs-40 camptonBold">¡Registro aprobado!</h1>
      </div>
      <div className="content-container">
        <p className="text-center fs-20 camptonLight fw-bold">
          Haz registrado un nuevo posible cliente Tandgo, encontrarás este
          registro en la pestaña: Mis registros &gt; Proceso de aprobación
        </p>
      </div>
      <div>
        <button className="camptonLight fw-bold fs-15 custom-button-primary w-auto p-3 d-flex justify-content-center align-items-center mb-4 mt-3">
          Crear nuevo registro
        </button>
      </div>
    </div>
  );
};
export default RegistroAprobado;
