import React from 'react'
import { MdLocationOn } from "react-icons/md";

const Datos = () => {
  return (
    <div className='col-12 col-lg-5 ps-4 d-flex flex-column align-items-center'>
            <div className='col-12 d-flex flex-row border-bottom align-items-center mt-2'>
                <div className='d-flex align-items-center col-1 mb-2'>
                    <img src='/assets/images/bolsaTrabajo/ubicacion.svg'></img>
                </div>
                <span className='fw-bold col-6 mb-2 camptonLight'>Ciudad:</span>
                <span className='fs-20 col-6 mb-2 camptonLight'>Monterrey</span>
            </div>

            <div className='col-12 d-flex flex-row border-bottom align-items-center mt-2'>
                <div className='d-flex align-items-center col-1 mb-2'>
                    <img src='/assets/images/bolsaTrabajo/calendario.svg'></img>
                </div>
                <span className='fw-bold col-6 mb-2 camptonLight'>Publicada el:</span>
                <span className='fs-20 col-6 mb-2 camptonLight'>12/05/22</span>
            </div>

            <div className='col-12 d-flex flex-row border-bottom align-items-center mt-2'>
                <div className='d-flex align-items-center col-1 mb-2'>
                    <img src='/assets/images/bolsaTrabajo/remuneracion.svg'></img>
                </div>
                <span className='fw-bold col-6 mb-2 camptonLight'>Remuneración:</span>
                <span className='fs-20 col-6 mb-2 camptonLight'>$15000</span>
            </div>

            <div className='col-12 d-flex flex-row border-bottom align-items-center mt-2'>
                <div className='d-flex align-items-center col-1 mb-2'>
                    <img src='/assets/images/bolsaTrabajo/modalidad.svg'></img>
                </div>
                <span className='fw-bold col-6 mb-2 camptonLight'>Modalidad:</span>
                <span className='fs-20 col-6 mb-2 camptonLight'>Presencial</span>
            </div>

            <div className='col-12 d-flex flex-row border-bottom align-items-center'>
                <div className='d-flex align-items-center col-1 mb-2'>
                    <img src='/assets/images/bolsaTrabajo/prestaciones.svg'></img>
                </div>
                <span className='fw-bold col-6 mb-2 camptonLight'>Ciudad:</span>
                <span className='fs-20 col-6 mb-2 camptonLight'>Monterrey</span>
            </div>
    </div>
  )
}

export default Datos
