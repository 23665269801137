import React from 'react'

const SubtitlePrimario = ({subtitle}) => {
  return (
    <div className='d-flex justify-content-center'>
        <span className='text-black p-2 text-center d-flex fs-25 camptonLight'>
            {subtitle}
        </span>
    </div>
  )
}

export default SubtitlePrimario