//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"
export const POST_LOGIN_TOKEN = "/login"
export const RESET_PASSW = "/resetPasword"
export const CHANGE_PASSW = "/changePasword"
export const SEND_CONFIRMATION = "/verify"
//catalogos

export const GET_HOURS = '/get-hours'
export const GET_ASUNTO = '/get-asuntos'
export const RESOURCE_VIPS = '/vips'
//REGISTRO DE ALIADOS
export const REGISTRO_ALIADO = 'registro-aliados'
//Solicitudes de clientes
export const SOLICITUD_CLIENTE = 'solicitud-clientes'
//usuarios
export const RESOURCE_USERS = '/users'
//Codigos de verificacion
export const GET_SMS_CODE = '/get-sms'
//SOLICITUDES DE CREDITO 
export const RESOURCE_CREDITS_REQUESTS = '/credits/requests'
//CONTACTANOS
export const RESOURCE_CONTACTS = '/contacts'
//ALIADOS
export const RESOURCE_ALIADOS = '/aliados'



//////////////////////////////////
export const SOLICITAR_CREDITO = 'solicitar-credito'
export const CONTACTANOS = 'contactanos'
export const ALIADOS_SOLICITUD = 'solicitud-aliado'