import axios from "axios"
import accessToken from "./jwt-token-access/auth-token-header"
import {getConfiguration} from './backend_helper'
import {ToastHelper} from './alerts/alertHelper';

export function getStorageUser() {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    return obj && 'user' in obj ? obj.user:{};
}

export function getToken() {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    return obj && 'token' in obj ? obj.token:false;
}

export function getPolicyUser(){
  const obj = JSON.parse(localStorage.getItem("authUser"))
  return obj && 'user' in obj ? obj.user.can:{configurations:false,users:false};
}


const API_URL = process.env.REACT_APP_BACKEND_API_URL
const axiosApi = axios.create({
    baseURL: API_URL
})
function addHeaderAuthorization() {
    const auth = accessToken()
    if ('Authorization' in auth)
        axiosApi.defaults.headers.common["Authorization"] = 'Bearer ' + auth.Authorization
}

export async function get(url, config = {}) {
    addHeaderAuthorization()
    return await axiosApi
    .get(url, { ...config })
    .then(response => {
        
        if(response.data.message){
            //ToastHelper(response.data.message,response.data.status ? 'success':'error')
        }
        return response.data
        }
    )
    .catch(err => {
        errors(err)
    })
}

export async function post(url, data, config = {}) {
    addHeaderAuthorization()
    return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => {
        if(response?.data?.message){
            ToastHelper(response.data.message,response.data.status ? 'success':'error')
        }
        if(response?.message){
            ToastHelper(response.message,response?.status ? 'success':'error')
        }
        
        return response.data
        }
    )
    .catch(err => {
        errors(err)
        return err;
        //return err
    })
}

export async function postData(url, data, config = {}) {
    addHeaderAuthorization()
    return axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
    .catch(err => {
        //errors(err)
        //return err
    })
}

export async function file(url, config = {}) {
    addHeaderAuthorization()
    return await axiosApi
    .get(url, { ...config })
    .then(response => response.data)
    .catch(err => {
        errors(err)
    })
}

export async function put(url, data, config = {}) {
    return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => {
        if(response?.data?.message){
            ToastHelper(response.data.message,response.data.status ? 'success':'error')
        }
        if(response?.message){
            ToastHelper(response.message,response?.status ? 'success':'error')
        }    
        return response.data
    })
    .catch(err => {
        errors(err)
    })
}

export async function del(url, config = {}) {
    return await axiosApi
    .delete(url, { ...config })
    .then(response => { 
        if(response.data.message){
            ToastHelper(response.data.message,response.data.status ? 'success':'error')
        }
        return response.data
    }).catch(err => {
        errors(err)
    })
}

export function postFile(url, data, config = {}){
    console.log(data.file)
    const formData = new FormData();
    formData.append('file', data.file);
    addHeaderAuthorization();
    return axiosApi
    .post(url, {formData,data}, { ...config })
    .then(response => response.data)
    .catch(err => {
        return err
    })
}

const errors = (e) => {
    var message = ''
    console.log('e', e)
    if (e.response && e.response.status) {
        switch (e.response.status) {
        case 404:
            message = "No se encuentra la pagina que buscas"
            break
        case 422:
            message = getError(e)
            break
        case 500:
            message ="Sorry! something went wrong, please contact our support team";
            break
        case 401:
            message = "Invalid credentials"
            localStorage.removeItem("authUser")
            window.location.replace('/login')
            break
        default:
            message = e.response?.data?.message || e.message
            break
        }
    } else {
        message = e.message
    }
    ToastHelper(message,'error')
}


const getError = err => {
    const message = 'Invalido';
    const data = err.response?.data
    if (data?.errors) {
        if (typeof data?.errors === 'object')
            return Object.values(data?.errors).join(', ')
        if (typeof data?.errors === 'object')
            return data?.errors.join(', ')
    }
    if (data?.message)
        return data?.message
    if (data?.error)
        return data?.error
    return message;
}