import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function Rango(props) {
    const [value, setValue] = useState(props.credit.frequency.total_payments);

    const handleIncrement = () => {
        setValue(prevValue => prevValue < 100 ? props.credit.frequency.total_payments : 100);
    };
    const [percentage, setPercentage] = useState(100);

    const handleDecrement = () => {
        setValue(prevValue => prevValue > 1 ? prevValue - 1 : 1);
    };

    const calculoCredito = () => {
        let percentageTotal = props.credit.vip.interestRate && props.credit.frequency.frecuency_days ? (parseFloat(props.credit.vip.interestRate) * (value * parseFloat(props.credit.frequency.frecuency_days))) : 0;
        let interestToPay = parseFloat(props.credit.amount) * (percentageTotal / 100)
        let amountTotal = (parseFloat(props.credit.amount) + (parseFloat(props.credit.amount) * (percentageTotal / 100))) + parseFloat(props.credit.vip.service)
        let payments = (amountTotal / value)

        props.setCredit({ ...props.credit, interestToPay: interestToPay, amountToPay: amountTotal, amountPayments: payments, paymentsTotal: value })
    }

    useEffect(() => {
        calculoCredito()
        setPercentage((value * 100) / props.credit.frequency.total_payments);
    }, [value]);

    useEffect(() => {
        setValue(props.credit.frequency.total_payments)
        calculoCredito()
    }, [props.credit.frequency, props.credit.vip, props.credit.amount]);

    const [current, setCurrent] = useState(4);
    const ProgressBar = ({ min = 1, max = 12, current }) => {
        const [value, setValue] = useState(4); // Initial value
        const barRef = useRef(null);

        const handleMouseMove = (e) => {
            if (barRef.current) {
                const rect = barRef.current.getBoundingClientRect();
                const newValue = Math.round(((e.clientX - rect.left) / rect.width) * 11) + 1; // Range 1 to 12
                setValue(Math.max(1, Math.min(12, newValue))); // Clamp between 1 and 12
            }
        };

        const handleMouseDown = () => {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        };

        const handleMouseUp = () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };

        return (
            <div className="progresss-container" ref={barRef} onMouseDown={handleMouseDown}>
                <div className="progresss-bar">

                    <div className="indicator" style={{ left: `${((value - 1) / 11) * 100}%` }}>
                    <div className="current-value">{value}</div>
                        ▲
                    </div>
                </div>
                <div className="labels">
                    <span className='start-prog'>1</span>
                    <span className='end-prog'>12</span>
                </div>
                
            </div>
        );
    };

    return (
        <>
            <div className=''>
                <ProgressBar current={current} />
            </div>
        </>

    );
}

Rango.propTypes = {
    credit: PropTypes.object.isRequired,
    setCredit: PropTypes.func.isRequired,
};
export default Rango;
