import React from 'react'
import { Link } from 'react-router-dom'
import "../styles/aliados.css"
import {CalculadoraMobible} from '../calculadora/CalculadoraMobile'


const AliadoTandgo = () => {
    return (
      <div className='d-flex justify-content-center align-items-center flex-column flex-lg-row flex-md-column mt-2 mt-lg-5 mt-md-5 col-12'>
          <div className='mt-0 ml-lg-5 mt-md-5 d-flex justify-content-center align-items-center '>
              <img src='/assets/images/aliados/unidos.png' className='img-custom-aliados'/>
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center text-white fw-bold col-12 col-lg-6'>
              <span className="fs-40 text-center col-12">Sé un aliado Tandgo</span>
              <span className="fs-30 text-center col-8">Comisiona por cada cliente que refieras en nuestra plataforma</span>
              <div className='d-flex flex-column flex-lg-row flex-lg-row mt-3 mt-md-3 mt-lg-5 col-12 justify-content-center align-items-center'>
                  <Link to={"/registro-aliados"} className="custom-button w-auto ps-3 px-3 col-10 col-lg-4 mt-4 mt-md-3 mt-lg-0 text-decoration-none d-flex justify-content-center align-items-center">Quiero ser aliado</Link>
                  <Link to={"/login"} className="custom-button-accept w-auto px-3 ps-3 col-10 col-lg-4 mt-3 mt-lg-0 text-decoration-none d-flex justify-content-center align-items-center">Soy aliado</Link>
              </div>
              
          </div>
      </div>
    )
  }
  
  export default AliadoTandgo