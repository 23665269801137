import React from 'react'

const Paginacion = () => {
  return (
    <div className='col-12 d-flex justify-content-center align-items-center mt-5 mb-4'>
        <div className='d-flex justify-content-center align-items-center'>
            <div className='px-2' style={{color: "#D9D9D9"}}>{"<"}</div>
            <div className='px-2' style={{color: "#34A8EB", border: "1px solid #34A8EB", borderRadius: "50%"}}>1</div>
            <div className='px-2'>2</div>
            <div className='px-2'>3</div>
            <div className='px-2'>4</div>
            <div className='px-2'>5</div>
            <div className='px-2'>{">"}</div>
        </div>
    </div>
  )
}

export default Paginacion