import React, { useState } from "react";
import Disculpa from "../Modales/SolicitarCredito/Disculpa";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {registroAliado} from '../../helpers/backend_helper'
import { useNavigate } from 'react-router-dom';

const Registra = ({ siguientePaso }) => {
    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        username: Yup.string()
          .required('El nombre de usuario es requerido'),
        phone: Yup.string()
          .matches(/^[0-9]+$/, 'El número telefónico debe contener solo números')
          .required('El número telefónico es requerido'),
        email: Yup.string()
          .email('Ingrese un email válido')
          .required('El email es requerido'),
        confirmEmail: Yup.string()
          .oneOf([Yup.ref('email'), null], 'Los correos electrónicos deben coincidir')
          .required('Confirma tu email'),
        password: Yup.string()
          .min(6, 'La contraseña debe tener al menos 6 caracteres')
          .required('La contraseña es requerida'),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
          .required('Confirma tu contraseña'),
      });
    const [showPreview, setShowPreview] = useState(false);

    const initialValues = {
        username: '',
        phone: '',
        email: '',
        confirmEmail: '',
        password: '',
        confirmPassword: ''
    }

    const [dataUser, setDataUser] = useState({...initialValues})

    const handleSubmit = async(values) => {
        values.aliado = true;
        const response = await registroAliado(values);
        if (response.status){
            navigate('/login');
        }else{

        }
    }

    return (
        <div style={{ width: "50em" }} className='col-12 col-md-6 d-flex flex-column justify-content-center align-items-center mb-5 mt-5'>

            <div className="col-12 justify-content-end align-items-center d-flex position-absolute">
                <img src="assets/images/aliados/rectanguloRegistro.svg" className="d-flex col-10"></img>
            </div>

            <div className="mt-3 d-flex d-lg-none">
                <h1 className="color-primary fw-bold border-primary-home p-2 col-12 text-start">
                    <span className=" mt-4 mb-4 fs-40 camptonBold spacing-1">
                        Registra tu usuario
                    </span>
                </h1>
            </div>

            <div className="mt-5 d-none d-lg-flex">
                <h1 className="color-primary fw-bold border-primary-home title-fachada-registro p-3 col-4 text-start">
                    <span className=" mt-4 mb-4 fs-40 camptonBold spacing-1">
                        Registra tu usuario
                    </span>
                </h1>
            </div>


            <div className='card border-0  cardRounded col-11 col-lg-12 d-flex  flex-lg-row-reverse'>

                <div className='card border-0 cardRounded col-12 col-lg-7 d-flex jusitfy-content-center align-items-center flex-column p-1' style={{ padding: '0px' }}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    >
                        {({ handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                        <div className="text-center border-bottom mb-4 p-3 col-12">
                            {/* <span className="text-center fs-20">Todos los campos de este formularios son obligatorios.</span> */}
                        </div>
                        <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3 mt-md-0'>
                            <label className='camptonMedium'>Registra tu nombre de usuario</label>
                            <Field  type="text" id="username" name="username" placeholder='Escribe tu nombre de usuario' className='inputContactanos camptonLight' />
                            <ErrorMessage name="username"/>
                        </div>
                        <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3'>
                            <label className='camptonMedium'>Registra tu número telefónico</label>
                            <Field  type="text" id="phone" name="phone" placeholder='Escribe tu número telefónico' className='inputContactanos camptonLight' />
                            <ErrorMessage name="phone"/>
                        </div>

                        <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3'>
                            <label className='camptonMedium'>Registra tu email</label>
                            <Field  type="text" id="email" name="email" placeholder='Escribe tu número telefónico' className='inputContactanos camptonLight' />
                            <ErrorMessage name="email"/>
                        </div>

                        <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3'>
                            <label className='camptonMedium'>Confirma tu email</label>
                            <Field  type="text" id="confirmEmail" name="confirmEmail" placeholder='Escribe nuevamente tu email' className='inputContactanos camptonLight' />
                            <ErrorMessage name="confirmEmail"/>
                        </div>

                        <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3'>
                            <label className='camptonMedium'>Registra tu contraseña</label>
                            <Field  type="password" id="password" name="password" placeholder='Escribe tu contraseña' className='inputContactanos camptonLight' />
                            <ErrorMessage name="password"/>
                        </div>

                        <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3'>
                            <label className='camptonMedium'>Confirma tu contraseña</label>
                            <Field  type="password" id="confirmPassword" name="confirmPassword" placeholder='Escribe nuevamente tu contraseña' className='inputContactanos camptonLight' />
                            <ErrorMessage name="confirmPassword"/>
                        </div>



                        <button type="submit"  className="custom-button-primary px-3 ps-3 w-auto mt-4 mb-4">
                            Registrar
                        </button>
                    </Form>
                     )}
                    </Formik>
                </div>
                <div className='d-none d-lg-flex border-0 col-6 mt-5 d-flex jusitfy-content-start align-items-start flex-column p-3' style={{ padding: '0px' }}>
                    <img src="/assets/images/aliados/registro.svg" alt='Preview' width={"110%"} className=' mt-4 d-flex' />
                </div>

            </div>
        </div>
    );
};

export default Registra;
