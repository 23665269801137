import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { loginSuccess } from "../../store/actions"
import { Form, Input, FormFeedback } from "reactstrap";
//Configuracion
import { getConfiguration } from '../../helpers/backend_helper';
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
//actionsRedux
import { postLoginToken } from "../../helpers/backend_helper"

const LoginPlataforma = (props) => {
  // Estado para mostrar/ocultar contraseña
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch()
  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Ingresa tu correo electronico"),
      password: Yup.string().required("Ingresa tu contraseña"),
    }),
    onSubmit: async(values) => {
      const response = await postLoginToken(values)
      if(response.isAuth){
        localStorage.setItem("authUser", JSON.stringify(response))
        dispatch(loginSuccess(response));
        props.navigate('/plataforma/registros');
      }
    }
  });

  document.title = "Iniciar sesión";


  return (
    <>
      <div className='d-flex justify-content-center align-items-center col-9'>

        <Form
          className="card cardRounded col-4 p-4 border-0 d-flex flex-column align-items-center"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >

          <span className='camptonBold fs-45'>Login</span>
          <span className='border-bottom col-12 mb-3'></span>
          <Input
            name="email"
            className="inputLogin camptonLight col-12 mt-3 mb-3 p-3"
            placeholder="Ingresa tu usuario"
            type="email"
            autoComplete='off'
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.email || ""}
            invalid={
              validation.touched.email && validation.errors.email ? true : false
            }
          />
          {validation.touched.email && validation.errors.email ? (
            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
          ) : null}
          <div className='position-relative col-12'>

            <Input
              name="password"
              className="inputLogin camptonLight col-12 mt-3 mb-3 p-3"
              placeholder="Ingresa tu contraseña"
              type={showPassword ? "text" : "password"}
              autoComplete='off'
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.password || ""}
              invalid={
                validation.touched.password && validation.errors.password ? true : false
              }
            />
            <span
              className='position-absolute top-50 translate-middle-y end-0 pe-3 cursor-pointer'
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <span className='cursor-pointer'>🫣</span> : <span className="cursor-pointer">👁️</span>}
            </span>
            {validation.touched.password && validation.errors.password ? (
              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
            ) : null}
          </div>
          <button
            className="text-decoration-none d-flex justify-content-center align-items-center custom-button-primary col-12 ps-3 px-3 mt-4 mb-3"
            type="submit"
          >
            Ingresar
          </button>

          <span className='color-tercero fs-15 camptonLight fw-bold mt-4 mb-3'>¿Olvidaste tu contraseña?</span>
          <div className='mt-4'>
            <span className='camptonLight fs-15 '>¿No tienes una cuenta?</span>
            <Link to="/registro-aliados"> Registrate</Link>
            <span className='ps-5 px-4 color-tercero camptonLight fw-bold'></span>
          </div>
        </Form>
        <div className='col-1'>
          <img src='/assets/images/aliados/LOGIN.svg' style={{ marginLeft: "-200px" }}></img>
        </div>
      </div>

      {/* <div className='col-12 justify-content-end align-items-end d-flex position-absolute'>
                <img src='/assets/images/aliados/RectangleLogin.svg' style={{minHeight: "300px", height: "600px", marginTop: "-530px", zIndex: "0"}} className='d-flex col-7'></img>
            </div> */}

    </>

  )
}

export default LoginPlataforma
