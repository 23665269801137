import React from 'react'

const CardProcesoVenta = ({request}) => {
  return (
    <div className='p-2 col-12 col-lg-6 col-xxl-4'>
      <div className='card shadow border-0 cardRedondoMisRegistros d-flex flex-column justiy-content-center align-items-center' style={{borderRadius: "15px"}}>
        <span className='camptonMedium fs-20 mt-3'>{request.client.name}</span>

        {/* Linea de progreso */}
        <div className="progress-line p-4">
            <div className="progress-item">
                <div className="dot-container">
                <div className='col-12 d-flex'>
                    <div className='dot'></div>
                    <div className='d-flex flex-column ps-3' style={{marginTop: "-5px"}}>
                        <div className='camptonMedium'>Fecha de inscripcion</div>
                        <div className='camptonLight'>{request.created_at}</div>
                    </div>
                </div>
                </div>
            </div>

            <div className="progress-item">
                <div className="dot-container">
                <div className='col-12 d-flex'>
                    <div className='dot'></div>
                    <div className='d-flex flex-column ps-3' style={{marginTop: "-5px"}}>
                        <div className='camptonMedium'>Vendedor Asignado</div>
                        <div className='camptonLight'>Aqui va el nombre del vendedor</div>
                    </div>
                </div>
                </div>
            </div>

            <div className="progress-item">
                <div className="dot-container">
                <div className='col-12 d-flex'>
                    <div className='dot'></div>
                    <div className='d-flex flex-column ps-3' style={{marginTop: "-5px"}}>
                        <div className='camptonMedium'>¿Ya fue visitado?</div>
                        <div className='camptonLight'>{request.visitado ? 'Si':'No'}</div>
                    </div>
                </div>
                </div>
            </div>
            {request.datesReagendada.map((reagendada)=>(
                <div className="progress-item" key={reagendada.id}>
                    <div className="dot-container">
                    <div className='col-12 d-flex'>
                        <div className='dot'></div>
                        <div className='d-flex flex-column ps-3' style={{marginTop: "-5px"}}>
                            <div className='camptonMedium'>Reprogramacion 1</div>
                            <div className='camptonLight'>{reagendada.fecha}</div>
                        </div>
                    </div>
                    </div>
                </div>    
            ))}
            
            <div className="progress-item">
                <div className="dot-container">
                <div className='col-12 d-flex'>
                    <div className='big-dot'></div>
                    <div className='d-flex flex-column ps-3 col-10' style={{marginTop: "-5px"}}>
                        <div className='camptonMedium'>{request.status.name}</div>
                    </div>
                </div>
                </div>
            </div>

        {/* ... (otros ítems de progreso) */}
        </div>

        <div className='col-12 d-flex justify-content-end align-items-end'>
          <div className='col-12 borderRoundedInscripcion mt-3 posibleComision'>
            <span className="text-white camptonLight fw-bold fs-15 px-2 ps-2" >Fecha de inscripcion: </span>
            <span className='fs-20 text-white camptonLight fw-bold ps-2 px-2'>{request.created_at}</span>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  )
}

export default CardProcesoVenta;
