import React from 'react'
import "../styles/quienesSomos.css"


const QuienesSomosHome = () => {
    return (

        <>
        <div className='d-flex justify-content-center align-items-center flex-column flex-xxl-row-reverse mt-2 mt-lg-5 mt-md-5'>
            <div className='mt-0 ml-lg-5 mt-md-5 d-flex justify-content-start '>
                <img src='/assets/images/aliados/unidos.png' className='img-custom-quienes'/>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-center align-items-xxl-start col-12 col-xxl-5 text-white fw-bold'>
                <h1 className="text-center text-xxl-start mt-0 mt-lg-4 mb-3 mb-lg-5 color-primary fw-bold justify-content-center justify-content-xxl-start align-items-center d-flex w-auto border-primary-home">
                    <span className="p-3 fs-30 camptonBold">
                        Tu mejor aliado
                    </span>
                </h1>
                <div className='col-12 justify-content-center justify-content-xxl-start align-items-center d-flex'>
                    <span className="text-center text-xxl-start fs-30 justify-content-center align-items-center d-flex col-10 col-lg-8 camptonLight fw-bold">Descripción corta de la historia, misión, visión o por qué es reconocida Tandgo comparado con la competencia.</span>
                </div>
                <div className='d-flex flex-column flex-lg-row mt-3 mt-md-3 mt-lg-5 justify-content-start justify-content-md-center justify-content-xxl-start align-items-center d-flex col-12'>
                    <button className="custom-button w-auto px-3 ps-3 col-10 col-lg-4 mt-4 mt-md-3 mt-lg-0">Solicitar Credito</button>
                    <button className="custom-button-primary w-auto px-3 ps-3 col-10 col-lg-4 mt-3 mt-lg-0">Trabaja con nosotros</button>
                </div>
            </div>
        </div>
        </>

      )
    }

export default QuienesSomosHome