import React, { useState } from 'react';
import { Calendar } from 'primereact/calendar';

const Calendario = ({hours,client,setClient,dateInput}) => {
  const [date, setDate] = useState(null);

  return (
    <div className='d-flex flex-column align-items-center jusitfy-content-center col-12'>
      {dateInput && 
        <div className='mb-3'>
            <Calendar value={client.date} onChange={(e) => setClient({...client,date:e.value})} dateFormat="dd/mm/yy" />
        </div>
      }
      {hours && hours.map((hour)=>( 
        <div key={hour.id} onClick={()=>setClient({...client,hour_id:hour.id})} className={`${client.hour_id==hour.id ? 'button-seleccion-fecha-seleccion':'button-seleccion-fecha'} mt-2 text-center d-flex align-items-center flex-column justify-content-center col-12 col-md-8`}>
          <h3 className='mt-3 fs-20 camptonMedium   '>Jornada de {hour.name}</h3>
          <p className='fs-15 camptonMedium  '>{hour.initial_time} a {hour.end_time}</p>
        </div>  
      )) }
    </div>
  );
};

export default Calendario;
