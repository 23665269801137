import React, { useState, useEffect } from 'react';

const Progress = () => {
  // Data ficticia
  const [data, setData] = useState([
    { id: 1, filled: false },
    { id: 2, filled: true },
    { id: 3, filled: true },
    { id: 4, filled: false },
    { id: 5, filled: false },
  ]);

//   QUEDA PENDIENTE DE DISEÑO

  return (
    <div className="col-12 d-flex justify-content-center aling-items-center">

      <img src='/assets/images/plataforma/rango.svg' className=' mt-5 col-12 d-flex justify-content-center aling-items-center'></img>

      {/* <div className="progress-bar">
        {data.map((point, index) => (
          <div key={index} className={`progress-segment ${point.filled ? 'filled' : ''}`}>
            <div className="progress-number">
              <span>{index + 1}</span>
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default Progress;
