import React, { useState } from "react";
import Disculpa from "../Modales/SolicitarCredito/Disculpa";
import Descarga from "./Descarga";

const Accede = ({siguientePaso, solicitar, setSolicitar}) => {

    const [showPreview, setShowPreview] = useState(false);
    const [image, setImage] = useState(null);


    const handleClick = () => {
        setShowPreview(false);
        setSolicitar(true);
      }

    return (
        <div  className='cuadroFactura d-flex flex-column justify-content-center align-items-center mb-5 mt-5'>

        { solicitar ? (
        // Se muestra cuando `solicitar` es true
        <div className="mt-3 d-flex d-lg-none">
            <h1 className="color-primary fw-bold border-primary-home p-2 col-12 text-start">
            <span className=" mt-4 mb-4 fs-40 camptonBold">
                Facturación en línea
            </span>
            </h1>
        </div>
        ) : (
        // Se muestra cuando `solicitar` es false
        <>
        <div className="mt-5 col-12 d-none d-lg-flex"  style={{width: "5em"}}>
            <h1 className="color-primary fw-bold border-primary-home title-fachada p-4 col-3 text-start">
            <span className=" mt-4 mb-4 fs-40 camptonBold">
                Accede a tu factura digital
            </span>
            </h1>
        </div>
        

        <div className="mt-5 col-10 d-flex d-lg-none">
            <h1 className="color-primary fw-bold border-primary-home p-4 col-12 text-start">
            <span className=" mt-4 mb-4 fs-40 camptonBold">
                Accede a tu factura digital
            </span>
            </h1>
        </div>

        </>
        )}

        
        <div className='card shadow cardRounded border-0 col-11 col-lg-10 d-flex  flex-lg-row-reverse'>


        { solicitar ? (
            // Se muestra cuando `solicitar` es true
            <div>
                <Descarga setSolicitar={setSolicitar} solicitar={solicitar}/>
            </div>
            ) : (
                <>
                <div className='card border-0 cardRounded  col-12 col-lg-8 d-flex jusitfy-content-center align-items-center flex-column p-3' style={{padding: '0px' }}>
                <form className="col-10 col-lg-9">

                    <div className="text-center border-bottom mb-4 p-3 col-12">
                        <span className="text-center fs-20 camptonLight">Todos los campos de este formularios son obligatorios.</span>
                    </div>
                                <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3 mt-md-0'>
                                    <label className='camptonMedium'>Código de crédito liquidado en las ultimas 48 horas</label>
                                    <input placeholder='Escribe el código de tu crédito' className='inputContactanos camptonLight'></input>
                                </div>
                                <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3'>
                                    <label className='camptonMedium'>Email de usuario Tandgo</label>
                                    <input placeholder='Escribe el email de usuario Tandgo' className='inputContactanos camptonLight'></input>
                                </div>
                                {/* esto debe aparecer cuando se ingresen los datos de los input y se le de en solicitar factura una vez */}
                                <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3'>
                                    <label className='camptonMedium'>RFC Registro Federal del Contribuyente</label>
                                    <input placeholder='Escribe tu RFC' className='inputContactanos camptonLight'></input>
                                </div>
                                <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3'>
                                    <label className='camptonMedium'>Domicilio fiscal</label>
                                    <input placeholder='Escribe tu domicilio' className='inputContactanos camptonLight'></input>
                                </div>
                                <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3'>
                                    <label className='camptonMedium'>Concepto del gasto</label>
                                    <input placeholder='Concepto' className='inputContactanos camptonLight'></input>
                                </div>

                </form>

                <button onClick={handleClick} className="custom-button-primary ps-3 px-3 w-auto mt-4 mb-4">
                Solicitar factura
                </button>
            </div>
            <div className='d-none d-lg-flex border-0 col-6 mt-5 d-flex jusitfy-content-start align-items-start flex-column p-3' style={{padding: '0px' }}>
                <img src="/assets/images/factura.svg" alt='Preview' width="100%" className='imgAccede mb-5 d-flex jusitfy-content-center align-items-center' />
            </div>
                </>


            )}

            


            
        </div>
    </div>
    );
};

export default Accede;
