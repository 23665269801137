import React from 'react'

const SubtitleSecundario = ({subtitle}) => {
  return (
    <div className='d-flex justify-content-center align-items-center col-8'>
        <span className='text-white mt-3 mb-3 p-2 text-center d-flex fs-25'>
            {subtitle}
        </span>
    </div>
  )
}

export default SubtitleSecundario