import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import InputNewsletter from '../components/formulario/InputNewsletter'
import Navbar from '../components/navbar/Navbar'
import SliderCrecimiento from '../components/slider/SliderCrecimiento'
import SliderExitos from '../components/slider/SliderExitos'
import SliderHome from '../components/slider/SliderHome'
import SliderProceso from '../components/slider/SliderProceso'
import TitlePrincipal from '../components/titles/TitlePrincipal'
import TitleSecundario from '../components/titles/TitleSecundario'

const LandingPage = () => {
    const [selectedVipImage, setSelectedVipImage] = useState(null);

  return (
    <>
    <div style={{backgroundImage: 'url(/assets/images/fondoMorado.png)', backgroundSize: '100% 100%', minHeight: '50vh'}} className="d-flex flex-column justify-content-center">
        <Navbar/>
       
        <SliderHome/>
    </div>
    </>
  )
}

export default LandingPage
