import React from 'react'

const TablaRegistroComisiones = () => {
    const datos = [
        { nombre: "Juan", credito: "$5000", calificacion: "4.5", comision: "$200", fecha: "2023/01/01" },
        { nombre: "Ana", credito: "$7000", calificacion: "4.2", comision: "$300", fecha: "2023/02/15" },
        { nombre: "Carlos", credito: "$6000", calificacion: "3.8", comision: "$250", fecha: "2023/03/20" },
        { nombre: "Carlos", credito: "$6000", calificacion: "3.8", comision: "$250", fecha: "2023/03/20" },
        { nombre: "Carlos", credito: "$6000", calificacion: "3.8", comision: "$250", fecha: "2023/03/20" },
        { nombre: "Carlos", credito: "$6000", calificacion: "3.8", comision: "$250", fecha: "2023/03/20" },
        { nombre: "Carlos", credito: "$6000", calificacion: "3.8", comision: "$250", fecha: "2023/03/20" },
        { nombre: "Ana", credito: "$7000", calificacion: "4.2", comision: "$300", fecha: "2023/02/15" },
        { nombre: "Ana", credito: "$7000", calificacion: "4.2", comision: "$300", fecha: "2023/02/15" },
        { nombre: "Ana", credito: "$7000", calificacion: "4.2", comision: "$300", fecha: "2023/02/15" },
    
        // ... más datos
      ];
    
      return (
        <div className="table-responsive">
          <table className="table align-middle">
            <thead>
              <tr className='text-center camptonLight fw-bold fs-15'>
                <th scope="col text-center">Nombre</th>
                <th scope="col text-center">Crédito solicitado</th>
                <th scope="col text-center">Calificación del crédito</th>
                <th scope="col text-center">Fecha de finalización</th>
                <th scope="col text-center">Comisión generada</th>
              </tr>
            </thead>
            <tbody>
              {datos.map((fila, index) => (
                <tr className='text-center camptonLight fs-15' key={index}>
                  <td>{fila.nombre}</td>
                  <td>{fila.credito}</td>
                  <td><span className="me-1">⭐</span> <span style={{ filter: 'blur(3px)' }}>{fila.calificacion}</span> </td>
                  <td>{fila.fecha}</td>
                  <td>{fila.comision}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    };

export default TablaRegistroComisiones