import React from 'react'
import LoginPlataforma from '../components/login/LoginPlataforma'
import Navbar from '../components/navbar/Navbar'
import "../components/styles/login.css"
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  return (
    <>
     <div style={{backgroundImage: 'url(/assets/images/fondoMorado.png)', backgroundSize: '100% 100%', minHeight: '50vh'}} className="">
        <Navbar/>
        <div className='' style={{marginTop: "100px"}}>
          <LoginPlataforma navigate={navigate}/>
        </div>
     </div>
     <div className='p-0 p-lg-5' style={{backgroundImage: 'url(/assets/images/fondoMorado.png)', backgroundSize: 'cover', height: '100%', transform: 'rotate(180deg)'}}>
      <div style={{transform: 'rotate(180deg)'}} className="p-5 col-12">
        <div className="d-none d-lg-flex d-flex justify-content-center align-items-center flex-column mt-5" style={{ height: "100%" }}>
          <img src='/assets/images/home/Footer.png' style={{ maxWidth: "100%", height: "50%" }} className=""/>
        </div>
      </div>
     </div>
    </>
  )
}

export default Login
