import React from 'react'

const InscribirCuenta = () => {
  return (
    <div className='d-flex justify-content-center align-items-center mt-3 mb-2'>
        <div className='col-3 d-flex jusify-content-center alig-items-center'>
            <img src='/assets/images/plataforma/pagoComisiones/configura.png' className='col-10'></img>
        </div>
        <div className='col-6  d-flex jusify-content-center alig-items-center flex-column'>
            <span className='camptonMedium fs-20 mt-3'>Inscribe tu cuenta bancaria donde recibiras tu pago</span>
            <span className='camptonLight fs-20 mt-2'>Aún no haz indicado la cuenta a traves de la cual pagaremos tus comisiones generadas</span>
            <button className='custom-button-primary ps-2 px-2 fs-20 col-7 mt-4'><span className='fs-20'>Configurar cuenta de pago</span></button>
        </div>
    </div>
  )
}

export default InscribirCuenta