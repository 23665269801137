import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Calculadora from "../calculadora/Calculadora";
import CalculadoraMobile from "../calculadora/CalculadoraMobile";
import "../styles/sliderHome.css"

const SliderHome = () => {
  return (
    <div style={{ position: 'relative' }} className="d-flex flex-column-reverse">
      <div className="positionCalculadora d-flex" style={{ zIndex: "9999999" }}>
        <Calculadora />
      </div>

      {/* <div className="positionCalculadora position-absolute d-flex"  style={{zIndex: "9999999"}}>
      <CalculadoraMobile/>
  </div> */}

      <Carousel interval={5000} controls={false} indicators={false} wrap={true} className="carrusel-home-desk">
        <Carousel.Item className=" px-5 justify-content-center carrusel-home-desk">
          <div className="col-11 col-md-6 mx-auto d-flex justify-content-center align-items-center">
            <div className="text-center d-flex justify-content-center align-items-center mt-2 mb-5 color-primary border-secundary-home">
              <span className="p-2 d-flex justify-content-center align-items-center h1 camptonBold spacing-1">
                Impulsar tus ideas de negocio <span style={{ color: "#61b5e3" }} className="fs-40">.</span>
              </span>
            </div>
          </div>
          <div className="d-none d-lg-flex justify-content-center flex-wrap">
            <img
              src="/assets/images/home/ideaNegocio.png"
              alt="Descripción de la imagen"
              className="imgCarrusel"
            />
          </div>
        </Carousel.Item>




        <Carousel.Item className=" px-5 justify-content-center">
          <div className="col-11 col-md-6 mx-auto d-flex justify-content-center align-items-center">
            <div className="text-center d-flex justify-content-center align-items-center mt-2 mb-5 color-primary border-secundary-home">
              <span className="p-2 d-flex justify-content-center align-items-center h1 camptonBold spacing-1">
                Renovar tu mercancía <span style={{ color: "#61b5e3" }} className="fs-40">.</span>
              </span>
            </div>
          </div>
          <div className="justify-content-center flex-wrap d-flex">
            <img
              src="/assets/images/home/renovarMercancia.png"
              alt="Descripción de la imagen"
              className="imgCarrusel"
            />
          </div>
        </Carousel.Item>

        <Carousel.Item className=" px-5 justify-content-center">
          <div className="col-11 col-md-6 mx-auto d-flex justify-content-center align-items-center">
            <div className="text-center d-flex justify-content-center align-items-center mt-2 mb-5 color-primary border-secundary-home">
              <span className="p-2 d-flex justify-content-center align-items-center h1 camptonBold spacing-1">
                Pagar tus impuestos<span style={{ color: "#61b5e3" }} className="fs-40">.</span>
              </span>
            </div>
          </div>
          <div className="justify-content-center flex-wrap d-flex">
            <img
              src="/assets/images/home/ideaNegocio.png"
              alt="Descripción de la imagen"
              className="imgCarrusel"
            />
          </div>
        </Carousel.Item>

        <Carousel.Item className=" px-5 justify-content-center">
          <div className="col-11 col-md-6 mx-auto d-flex justify-content-center align-items-center">
            <div className="text-center d-flex justify-content-center align-items-center mt-2 mb-5 color-primary border-secundary-home">
              <span className="p-2 d-flex justify-content-center align-items-center h1 camptonBold spacing-1">
                Pagar tu renta <span style={{ color: "#61b5e3" }} className="fs-40">.</span>
              </span>
            </div>
          </div>
          <div className="justify-content-center flex-wrap d-flex">
            <img
              src="/assets/images/home/pagarRenta.png"
              alt="Descripción de la imagen"
              className="imgCarrusel"
            />
          </div>
        </Carousel.Item>

        <Carousel.Item className=" px-5 justify-content-center">
          <div className="col-11 col-md-6 mx-auto d-flex justify-content-center align-items-center">
            <div className="text-center d-flex justify-content-center align-items-center mt-2 mb-5 color-primary border-secundary-home">
              <span className="p-2 d-flex justify-content-center align-items-center h1 camptonBold spacing-1">
                Amplia o renovar tu local <span style={{ color: "#61b5e3" }} className="fs-40">.</span>
              </span>
            </div>
          </div>
          <div className="justify-content-center flex-wrap d-flex">
            <img
              src="/assets/images/home/renovarLocal.png"
              alt="Descripción de la imagen"
              className="imgCarrusel"
            />
          </div>
        </Carousel.Item>

        <Carousel.Item className=" px-5 justify-content-center">
          <div className="col-11 col-md-6 mx-auto d-flex justify-content-center align-items-center">
            <div className="text-center d-flex justify-content-center align-items-center mt-2 mb-5 color-primary border-secundary-home">
              <span className="p-2 d-flex justify-content-center align-items-center h1 camptonBold spacing-1">
                Pagar tus impuestos <span style={{ color: "#61b5e3" }} className="fs-40">.</span>
              </span>
            </div>
          </div>
          <div className="justify-content-center flex-wrap d-flex">
            <img
              src="/assets/images/home/ideaNegocio.png"
              alt="Descripción de la imagen"
              className="imgCarrusel"
            />
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default SliderHome;
