import React from 'react';

const InputNewsletter = () => {
  return (
    <div className='d-flex justify-content-center align-items-center w-100'>
      <div className='position-relative mt-5 mb-5'>
        <input
          placeholder='Registra tu email y recibe promociones al instante'
          className='camptonLight form-control rounded-pill pr-5 inputNews'
        />
        <img
          src='/assets/images/home/button.png'
          className='imgButtonNews'
        />
      </div>
      <div className='d-none d-lg-flex ps-3'>
        <img src='/assets/images/home/newletter.png' width="80%" />
      </div>
    </div>
  );
};

export default InputNewsletter;
