import React, { useState } from 'react';

const Filtros = () => {
  const [seleccionado, setSeleccionado] = useState(''); // Estado para rastrear qué botón ha sido seleccionado
  
  const manejarClick = (nombre) => {
    setSeleccionado(nombre);
  }

  return (
    <div className='col-12 d-flex justify-content-center align-items-center flex-wrap'>
        <div className='col-12 d-flex justify-content-center flex-wrap'>
            <button 
              className={`button-seleccion-fecha${seleccionado === '15dias' ? '-seleccion' : ''} w-auto me-3 mb-2 ps-3 px-3`}
              onClick={() => manejarClick('15dias')}
            >
              <span className='fs-15'></span> Últimos 15 dias
            </button>
            <button 
              className={`button-seleccion-fecha${seleccionado === 'mes' ? '-seleccion' : ''} w-auto me-3 mb-2 ps-3 px-3`}
              onClick={() => manejarClick('mes')}
            >
              <span className='fs-15'></span> Último mes
            </button>
            <button 
              className={`button-seleccion-fecha${seleccionado === 'bimestre' ? '-seleccion' : ''} w-auto me-3 mb-2 ps-3 px-3`}
              onClick={() => manejarClick('bimestre')}
            >
              <span className='fs-15'></span> Último bimestre
            </button>
            <button 
              className={`button-seleccion-fecha${seleccionado === 'trimestre' ? '-seleccion' : ''} w-auto me-3 mb-2 ps-3 px-3`}
              onClick={() => manejarClick('trimestre')}
            >
              <span className='fs-15'></span> Último trimestre
            </button>
        </div>
    </div>
  );
};

export default Filtros;
