import React from 'react'
import Paginacion from '../../paginacion/Paginacion'
import SearchComisiones from '../comisiones/SearchComisiones'
import Card from './Card'

const SolicitudesDescartadas = ({setCreditRequests, creditRequests,setFilters,filters}) => {
    return (
        <div className='cards-container col-12 p-4'> 
          <SearchComisiones setFilters={setFilters} filters={filters}/>
          {creditRequests.map((request)=>(
            <Card key={request.id} request={request}/>
          ))}
          <Paginacion/>
        </div>
      )
    }
    

export default SolicitudesDescartadas