import React from 'react'

const TablaIncumplimiento = () => {
    const datos = [
        { nombre: "Juan", credito: "$5000", calificacion: "4.5", comisionAplazada: "$200", finalizacionIncumplida: "2023/01/01", finalizacionReestructuracion: "2023/01/01" },
        { nombre: "Juan", credito: "$5000", calificacion: "4.5", comisionAplazada: "$200", finalizacionIncumplida: "2023/01/01", finalizacionReestructuracion: "2023/01/01" },
        { nombre: "Juan", credito: "$5000", calificacion: "4.5", comisionAplazada: "$200", finalizacionIncumplida: "2023/01/01", finalizacionReestructuracion: "2023/01/01" },
        { nombre: "Juan", credito: "$5000", calificacion: "4.5", comisionAplazada: "$200", finalizacionIncumplida: "2023/01/01", finalizacionReestructuracion: "2023/01/01" },
        { nombre: "Juan", credito: "$5000", calificacion: "4.5", comisionAplazada: "$200", finalizacionIncumplida: "2023/01/01", finalizacionReestructuracion: "2023/01/01" },
        { nombre: "Juan", credito: "$5000", calificacion: "4.5", comisionAplazada: "$200", finalizacionIncumplida: "2023/01/01", finalizacionReestructuracion: "2023/01/01" },
        { nombre: "Juan", credito: "$5000", calificacion: "4.5", comisionAplazada: "$200", finalizacionIncumplida: "2023/01/01", finalizacionReestructuracion: "2023/01/01" },
        { nombre: "Juan", credito: "$5000", calificacion: "4.5", comisionAplazada: "$200", finalizacionIncumplida: "2023/01/01", finalizacionReestructuracion: "2023/01/01" },
    
        // ... más datos
      ];
    
      return (
        <div className="table-responsive">
          <table className="table align-middle">
            <thead>
              <tr className='text-center camptonLight fw-bold fs-15'>
                <th scope="col text-center">Nombre</th>
                <th scope="col text-center">Crédito solicitado</th>
                <th scope="col text-center">Calificación del crédito</th>
                <th scope="col text-center">Comisión Aplazada</th>
                <th scope="col text-center">Finalización incumplida</th>
                <th scope="col text-center">finalizacion reestructuracion</th>
              </tr>
            </thead>
            <tbody>
              {datos.map((fila, index) => (
                <tr className='text-center camptonLight fs-15' key={index}>
                  <td>{fila.nombre}</td>
                  <td>{fila.credito}</td>
                  <td><span className="me-1">⭐</span> <span style={{ filter: 'blur(3px)' }}>{fila.calificacion}</span></td>
                  <td>{fila.comisionAplazada}</td>
                  <td>{fila.finalizacionIncumplida}</td>
                  <td>{fila.finalizacionReestructuracion}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    };

export default TablaIncumplimiento