import React,{useState} from 'react'
import Paginacion from '../paginacion/Paginacion'
import SearchComisiones from '../registros/comisiones/SearchComisiones'
import Filtros from './Filtros'
import TablaHistorico from './TablaHistorico'

const HistoricoComisiones = () => {
  const [filters,setFilters] = useState({
    per_page:10,
    search:'',
    status:1,
  })
  return (
    <div className='cards-container col-12 p-4 d-flex flex-column'> 
        <SearchComisiones setFilters={setFilters} filters={filters}/>
        <Filtros/>

        <div className='d-flex justify-content-center col-12 align-items-center mt-4 mb-4 '>
            <div className='d-flex justify-content-between col-12 col-lg-10 align-items-center flex-column flex-lg-row'>
                <span className='camptonMedium fs-20'>Total de registros: 25 Clientes</span>
                <span className='camptonMedium fs-20 mt-3 mt-lg-0'>Comisión Total: 10 Clientes</span>
            </div>
        </div>


        <TablaHistorico/>

        <Paginacion/>

        <div></div>
        <div></div>
    </div>
  )
}

export default HistoricoComisiones