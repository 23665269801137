import React, { useState } from 'react'
import { getAuthUser, aliadoResource } from '../../../helpers/backend_helper'
import * as Yup from "yup";
import { useFormik } from "formik";

const Configuracion = () => {
    const authUser = getAuthUser();
    const [userAuth, setUserAuth] = useState({ ...authUser })
    const [editPhone, setEditPhone] = useState(false);
    const [editPassword, setEditPassword] = useState(false);
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: userAuth,
        validationSchema: Yup.object({
            phone: Yup.string().required('El número telefónico es obligatorio'),
            password: Yup.string()
                .min(6, 'La contraseña debe tener al menos 6 caracteres')
                .required('La contraseña es requerida'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
                .required('Confirma tu contraseña'),
        }),
        onSubmit: async values => {
            values.plataforma = true;
            values.editPassword = editPassword;
            const response = await aliadoResource('put', values,values.id);
            if (response.status) {//
                console.log(response.aliado)
                setUserAuth(response.aliado)
                setEditPassword(false)
                setEditPhone(false)
            } else {
                let errores = response.response?.data?.errors || {}
                validation.setErrors(errores);
            }
        },
    });
    return (
        <div className='col-12 p-3'>

            <div className='d-flex col-12 justify-content-between'>
                <div className='camptonBold fs-25 mt-2 mb-2 p-2'>Datos de usuario</div>
                <img src='/assets/images/plataforma/info.svg'></img>
            </div>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <div className='mb-4 d-flex col-12 flex-wrap ps-3 px-3 fs-20'>
                    <div className='camptonLight d-flex flex-column mt-3 col-4'>
                        <span>Email</span>
                        <span className='camptonMedium col-10'>{userAuth.email}</span>
                    </div>

                    <div className='camptonLight d-flex flex-column mt-3 col-4'>
                        <span>Nombre y Apellido</span>
                        <span className='camptonMedium'>{userAuth.name}</span>
                    </div>

                    <div className='camptonLight d-flex flex-column mt-3 col-4'>
                        <span>Número teléfonico</span>
                        {editPhone ?
                            <>
                                <input
                                    name="phone"
                                    placeholder='Escribe tu telefono'
                                    className={`inputContactanos camptonLight ${validation.touched.phone && validation.errors.phone ? 'has-error' : ''}`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.phone || ""}
                                />
                                {validation.touched.phone && validation.errors.phone && (
                                    <div className='error-text' style={{ color: 'red' }}>{validation.errors.phone}</div>
                                )}
                            </>
                            :
                            <span className='camptonMedium'>{authUser.phone}</span>
                        }
                    </div>
                    {editPassword &&
                        <div className='camptonLight d-flex flex-column mt-3 col-4'>
                            <span>Contraseña</span>
                            <input
                                name="password"
                                type='password'
                                placeholder='Escribe tu nueva contraseña'
                                className={`inputContactanos camptonLight ${validation.touched.password && validation.errors.password ? 'has-error' : ''}`}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                            />
                            {validation.touched.phone && validation.errors.password && (
                                <div className='error-text' style={{ color: 'red' }}>{validation.errors.password}</div>
                            )}
                        </div>
                    }
                    {editPassword &&
                        <div className='camptonLight d-flex flex-column mt-3 col-4'>
                            <span>Confirmar contraseña</span>
                            <input
                                name="confirmPassword"
                                type='password'
                                placeholder='Confirma la contraseña'
                                className={`inputContactanos camptonLight ${validation.touched.confirmPassword && validation.errors.confirmPassword ? 'has-error' : ''}`}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.confirmPassword || ""}
                            />
                            {validation.touched.confirmPassword && validation.errors.confirmPassword && (
                                <div className='error-text' style={{ color: 'red' }}>{validation.errors.confirmPassword}</div>
                            )}
                        </div>
                    }
                    <div className='camptonLight d-flex flex-column mt-3 col-4'>
                        <span>Ultimo cambio de clave</span>
                        <span className='camptonMedium'>{authUser.date_reset ? authUser.date_reset : '--'}</span>
                    </div>
                </div>

                <div className='mb-5 col-12 d-flex justify-content-center align-items-center'>
                    <a className={`w-auto ps-2 px-2 custom-button-${editPassword ? 'primary' : 'tarjeta'}`} onClick={() => setEditPassword(true)}>Actualización de clave</a>
                    <a className={`w-auto ps-2 px-2 custom-button-${editPhone ? 'primary' : 'tarjeta'}`} onClick={() => setEditPhone(true)}>Añadir Teléfono</a>
                    {(editPassword || editPhone) &&
                        <>
                            <button className={`w-auto ps-2 px-2 custom-button-tarjeta`} type='submit'>Guardar</button>
                            <button className={`w-auto ps-2 px-2 custom-button-tarjeta`} onClick={() => [setEditPhone(false), setEditPassword(false)]}>Cancelar</button>
                        </>
                    }
                </div>
            </form>
            <div className='d-flex col-12 justify-content-between'>
                <div className='camptonBold fs-25 mt-2 mb-4 p-2'>Datos bancarios para pagar tus comisiones</div>
                <img src='/assets/images/plataforma/info.svg'></img>
            </div>



            <div className='mb-3 d-flex flex-column col-6 flex-lg-row col-lg-12'>
                <img src='/assets/images/plataforma/tarjeta.svg' className='col-5'></img>
                <div className='d-flex flex-column col-6 justify-content-center align-items-center'>
                    <div className='camptonLight fs-20 col-10 text-start '>Numero de cuenta bancaria</div>
                    <div className='camptonMedium fs-20 col-10 text-start'>7583 8383 7645 3728</div>
                    <div className='camptonLight fs-20 col-10 text-start mt-2'>Nombre del titular de la cuenta</div>
                    <div className='camptonMedium fs-20 col-10 text-start'>Gabriela Garcia</div>
                    <div className='camptonLight fs-20 col-10 text-start mt-2'>Última Actualización de la cuenta</div>
                    <div className='camptonMedium fs-20 col-10 text-start'>-</div>
                </div>
            </div>

            <div className='mt-2 mb-4 col-12 d-flex justify-content-center align-items-center'>
                <button className='w-auto ps-2 px-2 custom-button-primary'>Editar cuenta</button>
                <button className='w-auto ps-2 px-2 custom-button-tarjeta'>Eliminar cuenta</button>
            </div>

            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default Configuracion