import React from "react";
import { useLocation, Link } from 'react-router-dom';
import {getToken} from '../../helpers/api_helper'

const Navbar = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-transparent col-11 col-md-8 col-lg-12 col-xxl-12 mx-auto" style={{zIndex: "99999999"}}>
      <div className="d-flex flex-row align-items-center mt-5 col-12">
        <Link className="navbar-brand d-flex justify-content-lg-end justify-content-between align-items-end col-lg-3 col-xl-3 col-xxl-4 col-9  px-lg-5 px-0" to="#" style={{minWidth: "10vw"}}>
          <img src="/assets/images/navbarLogo.png" alt="Logo" width="160" height="55" className="" />
        </Link>
        <button 
          className="navbar-toggler bg-white" 
          type="button" 
          data-bs-toggle="collapse" 
          data-bs-target="#navbarNav" 
          aria-controls="navbarNav" 
          aria-expanded="false" 
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse w-100  cardRounded" id="navbarNav">
        <ul className="navbar-nav">
            <li className="nav-item d-flex jusitfy-content-center align-items-center flex-column">
              <Link className={`nav-link ${currentPath === "/" ? "active camptonBold text-center" : "camptonMedium text-center"}`} to="/">Home</Link>
              <span className={`nav-link ${currentPath === "/" ? "lineaDebajo col-4 col-lg-12" : ""}`}></span>
            </li>
            <li className="nav-item d-flex jusitfy-content-center align-items-center flex-column">
              <Link className={`nav-link ${currentPath === "/solicitar" ? "active camptonMedium text-center" : "camptonMedium text-center"}`} to="/solicitar">Solicitar Crédito</Link>
              <span className={`nav-link ${currentPath === "/solicitar" ? "lineaDebajo col-4 col-lg-12" : ""}`}></span>
            </li>
            <li className="nav-item d-flex jusitfy-content-center align-items-center flex-column">
              <Link className={`nav-link ${currentPath === "/quienes-somos" ? "active camptonMedium text-center" : "camptonMedium text-center"}`} to="/quienes-somos">Quienes Somos</Link>
              <span className={`nav-link ${currentPath === "/quienes-somos" ? "lineaDebajo col-4 col-lg-12" : ""}`}></span>
            </li>
            <li className="nav-item d-flex jusitfy-content-center align-items-center flex-column">
              <Link className={`nav-link ${currentPath === "/aliados" ? "active camptonMedium text-center" : " camptonMedium text-center"}`} to="/aliados">Aliados</Link>
              <span className={`nav-link ${currentPath === "/aliados" ? "lineaDebajo col-4 col-lg-12" : ""}`}></span>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;




