import React from 'react'
import Navbar from '../navbar/Navbar'
import SubtitleBold from '../titles/SubtitleBold'
import SubtitlePrimario from '../titles/SubtitlePrimario'
import SubtitleSecundario from '../titles/SubtitleSecundario'
import SubtitleTercero from '../titles/SubtitleTercero'
import TitlePrincipal from '../titles/TitlePrincipal'

const AvisoPrivacidad = () => {
  return (
    <>
    <div style={{backgroundImage: 'url(/assets/images/QuienesSomos/fondoMorado.png)', backgroundSize: 'cover', height: '100%'}}>
      <Navbar/>
      <div className="d-flex align-items-center justify-content-center col-12 flex-column">
        <TitlePrincipal title={"Aviso de privacidad"}/>
        <SubtitleSecundario subtitle={`Exigidas por la Ley federal de protección de datos personales en posesión de los particulares publicada en el DOF el 5 de julio de 2010.`}/>
      </div>
    </div>

    <div className="col-12 d-flex flex-column justify-content-center align-items-center">

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`En Tandgo, la información y datos personales de los beneficiarios, colaboradores, donantes, asociados, empleados y proveedores, es tratada de forma estrictamente confidencial y con la secrecía necesaria para lograr los fines de la organización, por lo que hacemos un esfuerzo permanente para salvaguardarla, de conformidad con los artículos 8, 15, 16 y 36 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.`}/>


    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleBold subtitle={`Identidad y domicilio de la organización`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Tandgo, es una asociación civil sin fines de lucro, enfocada a [a qué se dedica la organización]. Nuestra misión es [misión].`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`En concordancia con el derecho a la protección de datos personales en posesión de particulares consagrado en el artículo 6° de la Constitución Política de los Estados Unidos Mexicanos, así como en cumplimiento a las disposiciones de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, y en base a los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad; [nombre de la organización], con domicilio en [dirección completa], es responsable de recabar y proteger los datos personales, así como del uso que se le dé a los mismos.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Si el titular de los datos personales no acepta en forma absoluta y completa los términos y condiciones de este aviso, deberá abstenerse de compartir cualquier tipo de información a Tandgo por cualquier medio físico, óptico y/o electrónico.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleBold subtitle={`Propósito para la recolección y uso de los datos​`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Los datos personales son utilizados para las siguientes finalidades:`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`A) En la comunicación con terceros y donantes nacionales e internacionales, con fines estadísticos, administrativos y de recaudación de fondos para apoyar la atención de los beneficiarios.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`B) Para el registro de los beneficios y obligaciones establecidas por las leyes mexicanas, así como para fines administrativos, de desarrollo y estadísticos, relacionados con nuestros trabajadores.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`C) Los datos personales de los donantes, personas físicas y morales, nacionales e internacionales, son utilizados de manera interna para fines estadísticos, administrativos y de recaudación de fondos; y de manera externa para trasparentar el origen y destino de los recursos operados por Tandgo.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`D) Adicionalmente a lo anterior, los datos personales de los diferentes grupos de interés de Tandgo, serán tratados en general para mantener una fluida comunicación con ellos a través del envió de información, invitaciones y felicitaciones, y llevar a cabo trámites administrativos.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleBold subtitle={`Datos personales recabados​`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Para los fines antes citados Tandgo, requiere obtener los siguientes datos personales:`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`​A) De las personas físicas`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Nombre completo.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Dirección.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Teléfono local y/o móvil.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Correo electrónico.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Fecha de nacimiento.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Lugar de nacimiento.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Estado Civil.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Escolaridad.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Ocupación laboral.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Sexo.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Información financiera en los casos que proceda.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Otro tipo de información específica, de acuerdo a las necesidades de las diferentes áreas y conforme a los fines de la organización.​`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`B) De las personas morales`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Nombre de la institución.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Registro Federal de Contribuyentes.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Nombre de los representantes.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Dirección.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Teléfono.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Correo electrónico.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Otro tipo de información específica, de acuerdo a las necesidades de las diferentes áreas y conforme a los fines de la organización.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleBold subtitle={`Manejo de datos personales sensible`}/>
    </div>


    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Algunos de los datos recabados que se mencionan en los incisos antes citados, son considerados como datos personales sensibles, por lo que el uso y tratamiento de los mismos por parte de la organización, se hace con el más estricto control, confidencialidad y secrecía necesarios para la proveeduría, protección y bienestar de nuestra población de interés, dicho manejo de los datos personales y datos personales sensibles es necesario para lograr las finalidades legítimas, concretas y acordes de Tandgo.​`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleBold subtitle={`​Opciones y medios para limitar el uso o divulgación de los datos`}/>
    </div>


    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`En todo momento el titular podrá limitar el uso o divulgación del tratamiento de sus datos personales, para tal efecto deberá ponerse en contacto a través del correo electrónico contacto@tango.mx y será necesario que presente su petición al responsable del manejo de la información de la organización cuyos datos de contacto vienen a continuación. `}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Medios para ejercer los derechos de acceso a los datos, rectificación, cancelación u oposición de los mismos, de conformidad con lo dispuesto por la Ley.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`El titular tiene derecho de acceder a sus datos personales que Tandgo posee y a los detalles del tratamiento de los mismos, rectificar en caso de ser inexactos o incompletos, cancelarlos cuando considere que no se requieren para alguna de las finalidades señaladas en el aviso de privacidad, así como a oponerse al tratamiento de los mismos y/o revocar en los casos que proceda, cuando la ley específica lo permita, el consentimiento que para tal fin nos haya otorgado, a través de los procedimientos que se han implementado para tal efecto. Para conocer más detalles sobre el procedimiento respectivo, requisitos y plazos, puede contactar a la [nombre], responsable de manejo de la información, al siguiente correo electrónico contacto@tandgo.mx.`}/>
    </div>



    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleBold subtitle={`​Procedimiento a seguir en caso de que el titular de los datos cambie de opinión posteriormente y decida revocar su consentimiento.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`La petición debe de ir acompañada de la siguiente información:`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Nombre completo de la persona que desea revocar el consentimiento y documento de identidad que acredite la personalidad o documento que acredite la representación legal en su caso.`}/>
    </div>


    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos antes mencionados.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Domicilio, correo electrónico u otro medio para comunicarle la respuesta a su solicitud.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Cualquier otro documento que facilite la localización de los datos personales.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Tandgo tendrá un plazo de 20 días contados, a partir de la fecha en que se recibió la solicitud, para atender y comunicarle al titular la determinación adoptada, a efecto de que, si resulta procedente, se haga efectiva la misma dentro de los 15 días siguientes a la fecha en que se comunica la respuesta.​ `}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Tratándose de solicitudes de acceso de datos personales, procederá la entrega previa acreditación de la identidad del solicitante o representante legal según corresponda. Los plazos antes referidos podrán ser ampliados una sola vez por un periodo igual, siempre y cuando así lo justifiquen las circunstancias del caso.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleBold subtitle={`Transferencia de datos​`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Para lograr el objeto y fines que persigue la organización, los datos personales pueden ser transferidos y tratados dentro y fuera del país. La información será compartida para fines estadísticos y administrativos. Si el titular de los datos personales no manifiesta oposición alguna y no lo informa al responsable mediante el procedimiento establecido anteriormente citado, se entenderá que ha otorgado su consentimiento para ello. No obstante lo anterior, Tandgo se compromete a que la información que se transfiere a terceros y que se encuentra prevista en las excepciones que fija la ley de la materia para tal efecto, sea tratada de forma responsable, confidencial y exclusivamente por aquellas personas que requieren del conocimiento de dichos datos.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleBold subtitle={`Procedimiento y medios por el cual se comunicará a los titulares de cambios al aviso de privacidad​`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Atendiendo las directrices de la autoridad competente, en su caso, de ser necesario Tandgo realizará cambios o modificaciones al aviso de privacidad, atendiendo también los fines de la organización. En su caso estas modificaciones estarán disponibles en la página web institucional o se harán llegar a la brevedad al último correo electrónico que los titulares nos hayan proporcionado.​`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Este documento atiende las disposiciones de la ley aplicable, para casos específicos y en atención al objeto social y fines de la organización, se atenderá la situación concreta y se le pondrá a la vista el aviso de privacidad simplificado cuyo contenido actualiza la información requerida en las fracciones I y II del artículo 16 de la ley de la materia y remite al presente aviso de privacidad completo. Cualquier modificación a este aviso de privacidad se podrá consultar en la página www.tandgo.com.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={`Si alguna persona (física o moral) considera que su derecho de protección de datos personales ha sido lesionado por alguna conducta de los colaboradores de Tandgo o de las actuaciones de la organización, presume que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en le Ley Federal de Protección de Datos Personales en Posesión de Particulares, podrá interponer la queja o denuncia correspondiente ante el IFAI.`}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleTercero subtitle={``}/>
    </div>

    <div className='d-flex align-items-center justify-content-center col-12'>
      <SubtitleBold subtitle={`​`}/>
    </div>

    </div>


    <div style={{backgroundImage: 'url(/assets/images/fondoMoradoFooter.png)', backgroundSize: 'cover', height: '100%'}}>

       <div className="d-none d-lg-flex d-flex justify-content-center align-items-center flex-column mt-5" style={{ height: "100%" }}>
         <img src='/assets/images/home/Footer.png' style={{ maxWidth: "80%", height: "50%" }} className=""/>
       </div>

    </div>


   </>
  )
}

export default AvisoPrivacidad