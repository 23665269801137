import React from 'react';

const SearchComisiones = ({filters,setFilters}) => {
  return (
    <div className="unique-search-container d-flex justify-content-center align-items-center col-12 mt-4 mb-4">
      <div className="unique-search-input-group d-flex justify-content-center align-items-center col-5">
        <input
          type="text"
          className="unique-search-input shadow col-12"
          placeholder="Buscar"
          aria-label="Buscar"
          onChange={(e)=>setFilters({...filters,search:e.target.value})}
          value={filters.search}
        />
        <div className="unique-search-inner-icon-wrap">
          <span className="unique-search-inner-icon">
            <i className="fa fa-search"></i>
          </span>
        </div>
      </div>
      <div className="unique-search-external-icon-wrap">
        <span className="unique-search-circle-icon">
          <i className="fa fa-filter"></i>
        </span>
      </div>
    </div>
  );
};


export default SearchComisiones;
