import axios from "axios"
import { del, get, post, postFile, put, postData} from "./api_helper"
import * as url from "./url_helper"
//import {ToastHelper} from './alerts/alertHelper';
// Gets the logged in user data from local session
const getLoggedInUser = () => {
    const user = localStorage.getItem("authUser")
    if (user) return JSON.parse(user)
        return null
}

const getAuthUser = () => {
    const user = localStorage.getItem("authUser")
    if (user) return JSON.parse(user).user
        return null
}

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null
}
//reset pasword
export const userResetPasw = user => post(url.RESET_PASSW, user)
export const changePassw = data => post(url.CHANGE_PASSW, data)
export const sendConfirmation = data => post(url.SEND_CONFIRMATION, data)



export const solicitarCredito = solicitud => post(url.SOLICITAR_CREDITO, solicitud)
// Login Method
//Registro de aliados
export const registroAliado = data => post(url.REGISTRO_ALIADO, data)
//Solicitudes de clientes
export const solicitudCliente = data => post(url.SOLICITUD_CLIENTE, data)
//Codigo de verificación
export const getSmsCode = data => post(url.GET_SMS_CODE, data)
//Contactanos
export const contactanos = data => post(url.CONTACTANOS, data)
export const aliados_solicitud = data => post(url.ALIADOS_SOLICITUD, data)

export const postLoginToken = (data) => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    return axios
    .post(process.env.REACT_APP_BACKEND_API_URL + url.POST_LOGIN_TOKEN, data, { headers: headers })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
          throw response.data
    })
    .catch(err => {
        console.log('err', err.response.data);
        var message = ''
        if (err.response && err.response.status) {
            switch (err.response.status) {
                case 404:
                    message = "Lo sentimos el recurso solicitado no existe"
                    break
                case 422:
                    message = err.response.data.message
                    break
                case 500:
                    message = "Lo sentimos! algo ha salido mal, por favor contacta a nuestro equipo de soporte."
                    break
                case 401:
                    message = "Credenciales inválidas"
                    break
                default:
                    message = err.response?.data?.message || err.message
                    break
            }
           // ToastHelper(message,'error')
        } else {
            message = err.message
        }
        throw message
    })
}

// get Users
export const GET_ME = "/user";
//Catalogos sin login
export const vipsResource = (type,data={},id=null) => typeResource(url.RESOURCE_VIPS,type,data,id)
export const getHours = config => get(url.GET_HOURS, config)
export const getAsunto = config => get(url.GET_ASUNTO, config)
//export const resourcesFuelSales = (type,data={},id=null) => typeResource(url.RESOURCE_FUEL_SALES,type,data,id)
//ARchivos
//Solicitudes de credito 
export const creditRequestsResource = (type,data={},id=null) => typeResource(url.RESOURCE_CREDITS_REQUESTS,type,data,id)
//Contactanos
export const contactResource = (type,data={},id=null) => typeResource(url.RESOURCE_CONTACTS,type,data,id)
//aliados
export const aliadoResource = (type,data={},id=null) => typeResource(url.RESOURCE_ALIADOS,type,data,id)

const typeResource = (route,type,data={},id=null) => {
    switch (type) {
    case 'get':
        return get(route,data);
        break
    case 'show':
        return get(`${route}/${id}`,data);
        break
    case 'post':
        return post(`${route}`,data);
        break
    case 'put':
        return put(`${route}/${id}`,data);
        break
    case 'del':
        return del(`${route}/${id}`);
        break
    case 'postFile':
        return postFile(`${route}`,data);
        break
    }

}

export {
    getLoggedInUser,
    isUserAuthenticated,
    getAuthUser
  //postFakeRegister,
  //postFakeProfile,
  //postFakeForgetPwd,
  //postJwtRegister,
  //postJwtLogin,
  //postJwtForgetPwd,
  //postJwtProfile
}
