import React, { useRef, useState, useEffect } from 'react';


const TarjetaPagos = ({avanzar,setSolicitud,solicitud}) => {
 
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [imageData, setImageData] = useState(null);
    const [cameraReady, setCameraReady] = useState(false);
  
    const iniciarCamara = async () => {
      setCameraReady(false);
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => {
          setCameraReady(true);
        };
      }
    };
  
    useEffect(() => {
      iniciarCamara();
      return () => {
        if (videoRef.current && videoRef.current.srcObject) {
          videoRef.current.srcObject.getTracks().forEach(track => track.stop());
        }
      };
    }, []);
  
    const capturarFachada = () => {
      const canvas = canvasRef.current;
      const video = videoRef.current;
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const data = canvas.toDataURL('image/png');
      setImageData(data);
    };
  
    const cancelarFachada = () => {
      setImageData(null);
      iniciarCamara();
    };
  
    const confirmarFachada = () => {
      setSolicitud({...solicitud,fileTargPagos:imageData})
      avanzar();
    };
  
    return (
      <div className='col-12 d-flex flex-column justify-content-center align-items-center mb-5'>
        <span className='camptonLight text-start col-11 d-flex justify-content-center fs-20'>
          Captura la tarjeta de pagos del cliente, encajándola dentro del recuadro negro. Porfavor que el texto sea legible, de lo contrario será rechazada
        </span>
  
        {/* Cuadro para la cámara */}
        <div className='camera-box mt-3 rounded'>
          {imageData ? (
            <img src={imageData} alt='Fachada Capturada' className='rounded' />
          ) : (
            <video className='rounded' ref={videoRef} width='640' height='480' autoPlay></video>
          )}
          <canvas className='rounded' ref={canvasRef} width='640' height='480' style={{ display: 'none' }}></canvas>
        </div>
  
        <div className='d-flex flex-column flex-lg-row mt-5 col-4 justify-content-between'>
          {imageData ? (
            <>
              <img src='/assets/images/plataforma/delete.svg' className='d-flex mt-1' onClick={cancelarFachada} style={{width: "2.5vw", height: "2.5vw", cursor: "pointer" }}>
              </img>
              <img src='/assets/images/plataforma/submit.svg' className='d-flex mt-1' onClick={confirmarFachada} style={{width: "2.5vw", height: "2.5vw", cursor: "pointer" }}>
              </img>
            </>
          ) : (
            <button 
              className=' col-10 w-auto custom-button-primary shadow cardRounded camptonLight d-flex flex-column justify-content-center align-items-center'
              onClick={capturarFachada}
              disabled={!cameraReady}
            >
              <span className='camptonLight ps-3 px-3 fs-20'>Capturar tarjeta de pagos</span>
            </button>
          )}
        </div>
      </div>
    );
  };

export default TarjetaPagos