import React, { useState } from 'react'
import MotivoRechazo from '../../../Modales/Plataforma/MisRegistros/MotivoRechazo';
import AvisoCasiListo from '../../../Modales/SolicitarCredito/AvisoCasiListo';

const Card = ({request}) => {

    const [showModal, setShowModal] = useState(false);

    const handleModalClose = () => {
        setShowModal(false);
    }

    return (
        <div className='p-2 col-12 col-lg-6'>
            <div className='card cardRounded shadow border-0 cardRedondoMisRegistros d-flex flex-column justiy-content-center align-items-center'>
                    <span className='camptonMedium fs-20 mt-3'>{request.client.name}</span>
    
    
                    <div className='d-flex flex-row justify-content-between col-11 mt-3'>
                        <div className='d-flex'>
                            <i></i>
                            <img src='/assets/images/plataforma/Phone.svg' className='d-flex mt-1' style={{width: ".8vw", height: ".8vw" }}></img>
                            <span className='camptonLight fs-15 ps-1'>{request.created_at}</span>
                        </div>
                        <div className='d-flex'>
                            <img src='/assets/images/plataforma/ubicacion.svg' className='d-flex mt-1' style={{width: ".8vw", height: ".8vw" }}></img>
                            <span className='camptonLight fs-15 ps-1'>{request.client.company.address} {request.client.company.floor_number} {request.client.company.postal_code}</span>
                        </div>
                    </div>
    
                    <div className='d-flex flex-row justify-content-between  col-11 mt-3'>
                        <div className='d-flex flex-column'>
                            <span className='camptonMedium fs-15'>Fecha de rechazo</span>
                            <span className='camptonLight fs-15'>{request.updated_at}</span>
                        </div>
                        <div className='d-flex flex-column'>
                            <span className='camptonMedium fs-15'>Rubro del negocio</span>
                            <span className='camptonLight fs-15'>{request.client.company.type_company}</span>
                        </div>
                    </div>
    
                    <div className='d-flex flex-row justify-content-between col-11 mt-3'>
                        <div className='d-flex flex-column'>
                            <span className='camptonMedium fs-15'>Registros Visuales</span>
                            <div className='d-flex'>
                                <img src='/assets/images/plataforma/file.svg' className='d-flex mt-1' style={{width: "1vw", height: "1vw" }}></img>
                                <img src='/assets/images/plataforma/shop.svg' className='d-flex mt-1' style={{width: "1vw", height: "1vw" }}></img>
                            </div>
                        </div>
                        <div className='d-flex flex-column'>
                            <span className='camptonMedium fs-15 col-10 text-center color-tercero'
                            style={{cursor: "pointer"}}
                            onClick={(e) => {
                                e.preventDefault();
                                setShowModal(true);
                            }}
                            >Ver motivo de rechazo</span>
                            <div>
                                <i></i>
                                <i></i>
                            </div>
                        </div>
                    </div>

                    <div className='col-12 d-flex justify-content-center align-items-center mt-4 mb-3'>
                        <button className='custom-button-primary w-auto ps-3 px-3 camptonLight fw-bold'
                        
                        > Reagendar</button>
                    </div>

                <MotivoRechazo isOpen={showModal} onClose={handleModalClose} request={request}/>

                
                    {/* <div className='col-12  d-flex justify-content-end align-items-end'>
                        <div className='col-10  borderRoundedInscripcion mt-3 fechaInscripcion'>
                            <span className="text-white  camptonLight fw-bold fs-15 px-2 ps-2" >Fecha de inscripcion: </span>
                            <span className='fs-20 text-white camptonLight fw-bold ps-2 px-2'>01/11/22</span>
                        </div>
                    </div> */}
    
    
            
                </div>
    
    
                <div></div>
            </div>
      )
    }

export default Card