import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import '../../styles/modales.css';

const EscaneoFallidoRostro = ({isOpen, onClose}) => {
    return (
        <Modal show={isOpen} onHide={onClose} centered size="lg">
            <Modal.Header className='d-flex flex-column border-0'>
                <img 
                    style={{width: '40%', height: 'auto'}} 
                    src="/assets/images/aliados/unidos.png" 
                    alt="Imagen de éxito" 
                />
                <h1 className='fw-bold fs-40'>Escaneo Fallido</h1>
            </Modal.Header>
            <div className="content-container">
                <p className='text-center fs-20'>El escaneo de tu rostro no se pudo completar, intentálo de nuevo</p>
            </div>
            <Modal.Footer>
                <button className="custom-button-primary w-auto p-3 d-flex justify-content-center align-items-center" onClick={onClose}>
                    Intentar nuevamente
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default EscaneoFallidoRostro;
