import React from 'react'
import { useNavigate } from 'react-router-dom';

const DashboardPage = () => {
  const navigate = useNavigate();
  
  const handleLogout = () => {
    localStorage.removeItem("token"); // Esto borrará el token del localStorage
    navigate('/'); // Esto redirigirá a los usuarios a la página principal
  }

  return (
    <div>
      DashboardPage
      <button onClick={handleLogout}>Logout</button>
    </div>
  )
}

export default DashboardPage;
