import React, { useState,useEffect } from 'react';
import MisSolicitudes from './solicitudes/MisSolicitudes';
import "../../styles/MisRegistros.css"
import EnProcesoVenta from './procesoVenta/EnProcesoVenta';
import MisComisiones from './comisiones/MisComisiones';
import SolicitudesDescartadas from './solicitudesRechazadas/SolicitudesDescartadas';
import {creditRequestsResource} from '../../../helpers/backend_helper'

const MisRegistros = () => {
  // Estado para rastrear la opción seleccionada
  const [creditRequests,setCreditRequests] = useState([]);
  const [filters,setFilters] = useState({
    per_page:10,
    search:'',
    status:1,
  })

  const [meta,setMeta] = useState({
    "current_page":1,
    "per_page":10,
    "total":0,
    "last_page":1,
  })

  useEffect(() => {
    const getCreditsRequests = async () => {
      let params = {params:filters}
      const response = await creditRequestsResource('get',params,null)
      if(response.status){
        setCreditRequests(response.creditRequests)
        setMeta(response.meta)
      }
    }
    getCreditsRequests();
  }, [filters]);

  

  const [selectedOption, setSelectedOption] = useState(filters.status);

  const Navegacion = () => {
    // Lista de opciones para simplificar el renderizado
    const options = [
      {status_id:1,name:'Mis solicitudes'},
      {status_id:2,name:'En proceso de venta'},
      {status_id:3,name:'Mis comisiones'},
      {status_id:4,name:'Solicitudes descartadas'}
    ];

    return (
      <div className='card roundedNavegacion col-10 d-flex flex-row mt-3 border-0 shadowNavegacion'>
        {options.map((option, index) => (
          <div
            key={index}
            className={`col-3 d-flex flex-column opcionNavegacion ${filters.status === option.status_id ? 'active' : ''}`}
            onClick={() => setFilters({...filters,status:option.status_id})}
          >
            <span className='camptonMedium fs-15 text-center'>{option.name}</span>
            <span className='camptonLight fs-15 text-center'> 60 </span>
          </div>
        ))}
      </div>
    );
  }


  const RenderContentRegistros = () => {
    switch (filters.status) {
      case 1:
        return <MisSolicitudes setFilters={setFilters} filters={filters} setCreditRequests={setCreditRequests} creditRequests={creditRequests}/>;
      case 2:
        return <EnProcesoVenta setFilters={setFilters} filters={filters} setCreditRequests={setCreditRequests} creditRequests={creditRequests}/>;
      case 3:
        return <MisComisiones setFilters={setFilters} filters={filters} meta={meta} setCreditRequests={setCreditRequests} creditRequests={creditRequests}/>;
      case 4:
        return <SolicitudesDescartadas setFilters={setFilters} filters={filters} setCreditRequests={setCreditRequests} creditRequests={creditRequests}/>;
      default:
        return <MisSolicitudes setFilters={setFilters} filters={filters} setCreditRequests={setCreditRequests} creditRequests={creditRequests}/>; // puedes poner un componente o mensaje default si lo deseas
    }
  };
  

  return (
    <>
    <div className='d-flex flex-row col-12 justify-content-center align-items center'>
      {Navegacion()}
      {/* aqui van las card o depende la seleccion de la navegacion */}
    </div>

    <div>
      {RenderContentRegistros()}
    </div>
    </>
  );
}

export default MisRegistros;
