import React from 'react';
import { Modal } from 'react-bootstrap';
import '../../styles/modales.css';

const Disculpa = ({isOpen, onClose}) => {
  return (
    <Modal show={isOpen} onHide={onClose} centered size="lg">
        <Modal.Header className='d-flex flex-column border-0'>
            <img 
                style={{width: '25%', height: 'auto'}} 
                src="/assets/images/aliados/losentimos.png" 
                alt="Imagen de éxito" 
            />
            <h1 className='fw-bold fs-40 camptonMedium'>Lo sentimos</h1>
        </Modal.Header>
        <div className="content-container">
            <p className='text-center fs-20 camptonLight'>Para ser cliente Tandgo debes tener un negocio propio.</p>
        </div>
        <Modal.Footer>
            <button className="custom-button-primary w-auto p-3 d-flex justify-content-center align-items-center camptonMedium" onClick={onClose}>
                Entendido
            </button>
        </Modal.Footer>
    </Modal>
)
}

export default Disculpa