import React, { useState } from 'react'
import SolicitudPago from '../../Modales/Plataforma/PagoComisiones/SolicitudPago';

const CuadroIndividual = ({title1, subtitle1}) => {

    const [showModal, setShowModal] = useState(false);

    const handleModalClose = () => {
        setShowModal(false);
    }

  return (
    <div className='espaciadoComisionesPrincipales'>
        <div className='col-12 col-lg-9 col-xxl-12  d-flex flex-column flex-lg-row justify-content-between align-items-center'>
            <div className='card shadowCuadroPincipal borderRoundedPrincipales col-12 m-3 col-lg-7  p-3 d-flex justify-content-center align-items-center'>
                <span className='camptonLight fs-15 text-center col-12'>{title1} </span>
                <span className='camptonMedium fs-20'>{subtitle1} </span>
            </div>

            <button 
                className='mt-3 mb-3 me-3 w-auto px-3 ps-3 custom-button-primary shadow cardRounded camptonLight d-flex flex-column justify-content-center align-items-center'
                onClick={(e) => {
                    e.preventDefault();
                    setShowModal(true);
                }}
                >
                <span className='camptonLight fs-20'>Cobrar</span>
            </button>
        </div>

        <SolicitudPago isOpen={showModal} onClose={handleModalClose} />

        </div>
  )
}

export default CuadroIndividual