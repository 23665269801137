import React from 'react'

const Puesto = () => {
  return (
    <div className="mt-5 col-12 col-lg-8 d-flex justify-content-start">
            <h1 className="color-primary fw-bold border-primary-detalle col-12 titleEmpleo d-flex w-auto p-2 text-start">
            <span className=" mt-2 mb-2 fs-20">
                Ejecutivo de cuentas
            </span>
            </h1>
        </div>
  )
}

export default Puesto