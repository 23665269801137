import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cuadro from './Cuadro';

const General = () => {
  // Estado que rastrea qué span está seleccionado
  const [selectedSpan, setSelectedSpan] = useState(0);

  useEffect(() => {
    const getMenuState = async () => {
       let obj = JSON.parse(localStorage.getItem("menuState"))
       let menuState = obj ? obj:0;
      setSelectedSpan(menuState)
    }
    getMenuState();
  }, []);

  // Hook para acceder a las funciones de navegación
  const navigate = useNavigate(); // Usa useNavigate aquí

  // Función que maneja el cambio de selección
  const handleSpanClick = (spanIndex) => {
    if (spanIndex === 5) { // Si el índice corresponde a "Cerrar sesión"
      localStorage.removeItem("menuState")
      navigate('/aliados'); // Usa navigate en lugar de history.push
      return;
    }
    localStorage.setItem("menuState", JSON.stringify(spanIndex))
    setSelectedSpan(spanIndex);
  }

  return (
    <div className='d-flex justify-content-center align-items-center col-12' style={{ marginTop: "0em" }}>
      <div className='card col-9  d-flex border-0' style={{ backgroundColor: "#fbfbfb", borderBottomRightRadius: "30px", borderTopLeftRadius: "30px", borderTopRightRadius: "0px", borderBottomLeftRadius: "0px", height: "100%" }}>
        <div className='col-12 d-flex'>
          <div className='col-3 d-flex flex-column' style={{ backgroundColor: "#f2f2f2", borderTopLeftRadius: "30px" }}>
            {['Mis registros', 'Nuevo registro', 'Histórico de comisiones', 'Pago de comisiones', 'Configuración', 'Cerrar sesión'].map((label, index) => (
              <span
                style={{ cursor: "pointer" }}
                key={index}
                className={index === selectedSpan ? 'camptonMedium border-bottom p-3 selectedOption' : 'camptonLight' + ' border-bottom p-3'}
                onClick={() => handleSpanClick(index)}
              >
                {label}
              </span>
            ))}
          </div>
          <div className='col-9' style={{ backgroundColor: "#fbfbfb", borderBottomRightRadius: "30px", height: "100%", minHeight: "60em" }} >
            <Cuadro selected={selectedSpan} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default General;
