import React from 'react'

const Opciones = ({ seleccionarOpcion }) => {
  return (
    <div className='col-12 d-flex flex-column justify-content-center align-items-center mt-5'>
        <span className='camptonLight text-start col-11 d-flex justify-content-center fs-20'>Para iniciar el registro de un posible cliente, debes especificar si te encuentras frente al negocio o si lo vas a cargar desde otro lugar:</span>
        <div className='d-flex flex-column flex-lg-row mt-5 col-12 justify-content-center'>
            <button onClick={() => seleccionarOpcion('frente')} className='col-2 me-3 buttonOpcionesNuevoRegistro shadow cardRounded camptonLight d-flex flex-column justify-content-center align-items-center'>
                <img src='/assets/images/plataforma/nuevoRegistro/opcion1.png' className='col-4 mt-2 mb-2'></img>
                <span className='camptonLight col-lg-8 col-10 fs-15'>Estoy frente al negocio</span>
            </button>
            <button onClick={() => seleccionarOpcion('lejos')} className='col-2 me-3 buttonOpcionesNuevoRegistro shadow cardRounded camptonLight d-flex flex-column justify-content-center align-items-center'>
                <img src='/assets/images/plataforma/nuevoRegistro/opcion2.png' className='col-4 mt-2 mb-2'></img>
                <span className='camptonLight col-lg-8 col-10 fs-15'>Estoy lejos del negocio</span>
            </button>
        </div>
    </div>
  )
}

export default Opciones;
