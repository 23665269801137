import React from 'react'
import Paginacion from '../paginacion/Paginacion'
import CuadrosPrincipales from '../registros/comisiones/CuadrosPrincipales'
import CuadroIndividual from './CuadroIndividual'
import InscribirCuenta from './InscribirCuenta'
import TablaIncumplimiento from './TablaIncumplimiento'
import TablaPagos from './TablaPagos'
import TablaRegistroComisiones from './TablaRegistroComisiones'

const PagoComisiones = () => {
  return (
    <>
    <div></div>
    <div></div>
    
    <div className='col-12 d-flex justify-content-center align-items-center'>
        <InscribirCuenta/>
    </div>

    <div className='col-12 d-flex justify-content-center align-items-center'>
        <CuadrosPrincipales 
            title1={"Mes de corte"} 
            subtitle1={"Junio 2022"} 

            title2={"Comisión acumulada"} 
            subtitle2={"$4.640"} 

            title3={"Próxima fecha de cierre"} 
            subtitle3={"28/06/2022"} 

            title4={"Te pagaremos el"} 
            subtitle4={"31/06/2022"} 
            />
    </div>


    
    <div className='col-12 d-flex justify-content-center align-items-center'>
        <span className='camptonLight p-3 fs-20'>Recuerda que estas comisiones serán pagadas a finalizar mes.</span>
    </div>

    <div className='col-12 d-flex justify-content-center align-items-center'>
        <span className='camptonMedium p-3 fs-20'>Registro de comisiones acumuladas en este corte.</span>
    </div>

    <div className='col-12 ps-3 px-3 mb-5 mt-3'>
        <TablaRegistroComisiones/>
    </div>

    <Paginacion/>



    <div className='col-12 d-flex justify-content-center align-items-center'>
        <CuadroIndividual title1={"Comisiones por cobrar en el corte"} subtitle1={"$4.640"} />
    </div>


    <div className="content-container mt-5"></div>

    <div className='col-12 d-flex justify-content-center align-items-center'>
        <span className='camptonMedium p-3 fs-20'>Histórico de pagos</span>
    </div>

    <div className='col-12 ps-3 px-3 mb-5 mt-3'>
        <TablaPagos/>
    </div>

    <Paginacion/>



    <div className="content-container mt-5"></div>

    <div className='col-12 d-flex justify-content-center align-items-center'>
        <span className='camptonMedium p-3 fs-20 col-12 col-lg-7 text-center'>Comisiones que no entran en el corte por incumplimiento y reestructuración</span>
    </div>

    <div className='col-12 ps-3 px-3 mb-5 mt-3'>
        <TablaIncumplimiento/>
    </div>


    <div></div>
    <div></div>

    </>

  )
}

export default PagoComisiones