import React from 'react';
import { Link } from 'react-router-dom';
import Configuracion from './configuracion/Configuracion';
import HistoricoComisiones from './HistoricoComisiones/HistoricoComisiones';
import NuevoRegistro from './nuevoRegistro/NuevoRegistro';
import PagoComisiones from './pagoComisiones/PagoComisiones';
import MisRegistros from './registros/MisRegistros';

const Cuadro = ({ selected }) => {
  
  const renderContent = () => {
    switch (selected) {
      case 0:
        return <MisRegistros/>;
      case 1:
        return <NuevoRegistro/>;
      case 2:
        return <HistoricoComisiones/>;
      case 3:
        return <PagoComisiones/>;
      case 4:
        return <Configuracion/>;

      // ... otros casos, puedes agregar tantos como necesites
      default:
        return <MisRegistros/>;
    }
  };



  return (
    <div>
      {renderContent()}
    </div>
  );
}

export default Cuadro;
