import React, { useState, useEffect } from "react";
import Rango from "./Rango";
import CreditoSimulacion from "../Modales/Calculadora/CreditoSimulacion";
import axios from "axios";
import SliderCrecimientoCalculadora from "../slider/SliderCrecimientoCalculadora";
import { vipsResource } from "../../helpers/backend_helper";

const Calculadora = () => {
  const [frecuencias, setFrecuencias] = useState([]);
  const [vips, setVips] = useState([]);

  const [credit, setCredit] = useState({
    amount: 0,
    frequency: {},
    vip: {},
    amountToPay: 0,
    amountPayments: 0,
    show: false,
    paymentsTotal: 0,
    interestToPay: 0,
  });

  useEffect(() => {
    setFrecuencias(credit.vip.frecuencies);
    console.log(credit.vip.frecuencies, "Frecuencias de vip");
    if (credit.vip.frecuencies?.length) {
      setCredit({ ...credit, frequency: credit.vip.frecuencies[0] });
    }
  }, [credit.vip]);

  return (
    <div className="iphone col-12 p-3" style={{ zIndex: "99999" }}>
      <div className="col-7 col-md-7 d-flex flex-column justify-content-center align-items-center iphoneDisplay">
        <div className="text-tand-base fs-15 camptonMedium mb-lg-2">
          Como quieres pagar tu prestamo
        </div>
        <div className="d-flex justify-content-center mb-1 flex-column flex-lg-row col-12 col-lg-12 justify-content-center">
          <button
            className={`button-calc ps-3 px-3 mt-0 mt-lg-0 ${credit.frequency === "diario" ? "button-calc-active" : ""
              }`}
            onClick={() => setCredit({ ...credit, frequency: "diario" })}
          >
            Diario
          </button>
          <button
            className={`button-calc ps-3 px-2 mt-0 mt-lg-0 ${credit.frequency === "semanal" ? "button-calc-active" : ""
              }`}
            onClick={() => setCredit({ ...credit, frequency: "semanal" })}
          >
            Semanal
          </button>
        </div>

        <div className="fs-15 camptonMedium mb-2">NUMERO DE PAGOS</div>
        <div className="mb-0 mb-lg-3">
          <Rango credit={credit} setCredit={setCredit} />
        </div>

        <div className="fs-15 camptonMedium mb-2">MONTO SOLICITADO</div>
        <div className="mb-0 mb-lg-3">
          <Rango credit={credit} setCredit={setCredit} />
        </div>

        <div className="fs-15 camptonMedium mb-1">VALOR DE TU PAGO</div>
        <div className="mb-1 mb-lg-3">$12</div>
        <div>
          <button
            className="custom-button-primary w-auto ps-3 px-3 fs-11"
            style={{ borderRadius: "20px", fontSize:'12px' }}
            onClick={() => setCredit({ ...credit, show: true })}
          >
            LO QUIERO
          </button>
        </div>
        <div>
          <div className="accordion" id="accordionExample">
            <div className="card acordeonCalc border-custom" >
              <div className="card-header headAcordion" id="headingOne">
                <h2 className="mb-0">
                  <button
                    className="btn btn-link d-flex justify-content-center align-items-center"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    style={{ width: "100%" }}
                  >
                    <svg
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      width="512.000000pt"
                      height="12.000000pt"
                      viewBox="0 0 512.000000 512.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                        fill="#000000"
                        stroke="none"
                      >
                        <path
                          d="M2495 3615 c-22 -8 -42 -15 -45 -15 -3 0 -353 -348 -778 -772 -471
                -471 -782 -789 -794 -813 -67 -128 22 -291 166 -303 107 -9 71 -40 824 711
                l692 692 693 -692 c752 -751 716 -720 823 -711 107 9 187 98 187 207 0 33 -7
                70 -17 91 -24 47 -1540 1566 -1588 1591 -52 27 -115 32 -163 14z"
                        />
                      </g>
                    </svg>
                  </button>
                </h2>
              </div>

              <div
                id="collapseOne"
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
              >
                <div className="card-body ">
                  <table>
                    <tbody style={{ fontSize: "12px" }}>
                      <tr>
                        <td>INTERÉSES</td>
                        <td className="d-flex justify-content-end align-items-start">10</td>
                      </tr>
                      <tr>
                        <td>COMICIONES</td>
                        <td className="d-flex justify-content-end align-items-start">10</td>
                      </tr>
                      <tr>
                        <td>FECHA DE LIQUIDACION</td>
                        <td className="d-flex justify-content-end align-items-start">10</td>
                      </tr>
                      <tr>
                        <td>TOTAL A PAGAR</td>
                        <td className="d-flex justify-content-end align-items-start">10</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td className="d-flex justify-content-end align-items-start" style={{ fontSize: "9px" }}>SIN IVA</td>
                      </tr>
                      <tr>
                        <td>CAT PROMEDIO</td>
                        <td className="d-flex justify-content-end align-items-start">10</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-center">
                    <button
                      className="custom-button-primary w-auto ps-3 px-3 fs-12"
                      style={{ borderRadius: "20px", fontSize:'12px' }}
                      onClick={() => setCredit({ ...credit, show: true })}
                    >
                      LO QUIERO
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calculadora;
