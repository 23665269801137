import React from 'react';

const CargoImagenCorrecto = ({retroceder, imagen, setImagen, avanzar}) => {

    const borrarImagen = () => {
        setImagen(null); // Resetea el estado de la imagen
        retroceder();    // Regresa al paso anterior
      };
      

  return (
    <div className='d-flex flex-column justify-content-center align-items-center'>


    <div className='mb-5'>
      <span className='camptonLight fs-20'>Confirma si cargaste la fotografía correctamente.</span>
    </div>

    <div className='col-12 d-flex justify-content-center align-items-center mt-3 mb-5'>
      <p className='nombreArchivo w-auto ps-3 px-3 fs-20'>{imagen ? imagen.name : null} <i className='ps-5 fa fa-trash' onClick={borrarImagen} style={{cursor: "pointer"}} ></i></p>
    </div>

    <button 
        className='mt-3 mb-5 me-3 w-auto custom-button-primary shadow cardRounded camptonLight d-flex flex-column justify-content-center align-items-center'
        onClick={avanzar}
        >
        <span className='camptonLight ps-3 px-3 fs-20'>Confirmar</span>
    </button>

      
    </div>
  );
};

export default CargoImagenCorrecto;
