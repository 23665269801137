import React from 'react'
import { Modal } from 'react-bootstrap';
import '../../../styles/modales.css';

const SolicitudPago = ({isOpen, onClose}) => {
    return (
      <Modal show={isOpen} onHide={onClose} centered size="lg">
        <Modal.Header className="d-flex flex-column border-0">
          <img
            style={{ width: "30%", height: "auto" }}
            src="/assets/images/plataforma/pagoComisiones/admiracion.png"
            alt="Imagen de éxito"
          />
          <h1 className="fw-bold fs-40 camptonMedium">Solicitud de pago</h1>
        </Modal.Header>
            <div className="content-container col-10 col-lg-11 d-flex justify-content-center">
                <div className=" d-flex flex-column align-items-center">
                    <div className="d-flex flex-column col-11 col-md-8 ps-0 ps-md-0 mt-3 mt-md-0">
                        <label className="camptonMedium mt-3 fs-15">
                            Registra tu número de cuenta bancaria
                        </label>
                        <input placeholder="Escribe" className="inputContactanos camptonLight fs-15"></input>
                    </div>

                    <div className="d-flex flex-column col-11 col-md-8 ps-0 ps-md-0 mt-3 mt-md-0">
                        <label className="camptonMedium mt-3 fs-15">
                            Confirma tu número de cuenta bancaria
                        </label>
                        <input placeholder="Escribe" className="inputContactanos camptonLight fs-15"></input>
                    </div>

                    <div className="d-flex flex-column col-11 col-md-8 ps-0 ps-md-0 mt-3 mt-md-0">
                        <label className="camptonMedium fs-15 mt-3">
                            Nombre completo del titular de la cuenta
                        </label>
                        <input placeholder="Escribe" className="inputContactanos camptonLight fs-15"></input>
                    </div>

                    <p className="text-start fs-15 camptonLight col-9 mt-4">
                        Recuerda que estas comisiones serán pagadas a finalizar mes (fecha exacta)
                    </p>
                </div>
            </div>

        <Modal.Footer>
          <button
            className="custom-button-primary w-auto p-3 d-flex justify-content-center align-items-center camptonMedium fs-15"
            onClick={onClose}
          >
            Entendido
          </button>
        </Modal.Footer>
      </Modal>
    );
    }

export default SolicitudPago