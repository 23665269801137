import React, { useState } from 'react';
import Mapa from './Mapa';  // Asegúrate de que esta ruta es correcta

const Ubicacion = ({ avanzar,solicitud,setSolicitud,showMapa }) => {
  const [mostrarMapa, setMostrarMapa] = useState(showMapa);

  const manejarCaptura = () => {
    setMostrarMapa(true);
  }

  return (
    <div className='col-12 d-flex flex-column justify-content-center align-items-center mb-5'>
      {mostrarMapa ? (
        <Mapa avanzar={avanzar} solicitud={solicitud} setSolicitud={setSolicitud}/>
      ) : (
        <>
          <span className='camptonLight text-start col-11 d-flex justify-content-center fs-20'>
            Vamos a capturar tu ubicacion GPS. Porfavor ubícate enfrente al negocio del cliente  para tener una posicion certera.
          </span>
          <div className='d-flex flex-column flex-lg-row mt-5 col-12 justify-content-center'>
            <button 
              className='me-3 w-auto custom-button-primary shadow cardRounded camptonLight d-flex flex-column justify-content-center align-items-center'
              onClick={manejarCaptura}
            >
              <span className='camptonLight ps-3 px-3 fs-20'>Capturar Ubicación</span>
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default Ubicacion;
