import React from 'react'
import { Modal } from 'react-bootstrap';
import '../../../styles/modales.css';

const MotivoRechazo = ({isOpen, onClose, request}) => {
    return (
        <Modal show={isOpen} onHide={onClose} centered size="lg">
            <Modal.Header className='d-flex flex-column border-0'>
                <img 
                    style={{width: '30%', height: 'auto'}} 
                    src="/assets/images/plataforma/lupa.png" 
                    alt="Imagen de éxito" 
                />
                <h1 className='fw-bold fs-40'>Solicitudes descartadas</h1>
            </Modal.Header>
            <div className="content-container">
                <p className='camptonMedium text-center fs-20'>Motivo de rechazo</p>
                <p className='camptonLight text-center fs-20'>{request.note}</p>
                <p className='camptonMedium text-center fs-20'>Sugerencias:</p>
                <p className='camptonLight text-center fs-20'>{request.recommendation}</p>
            </div>
            <Modal.Footer>
                <button className="camptonLight fw-bold custom-button-primary w-auto p-3 d-flex justify-content-center align-items-center" onClick={onClose}>
                    Entendido
                </button>
            </Modal.Footer>
        </Modal>
    )
    }

export default MotivoRechazo