import React, { useState } from 'react';
import '../styles/FloatingButton.css';

const FloatingButton = () => {
  const [showText, setShowText] = useState(true);

  const handleClose = () => {
    setShowText(false);
  };

  return (
    <div className="floating-button">
      {showText && (
        <div className="floating-text">
          ¡Hola! Estoy aquí para ayudarte
          <span className="close-button" onClick={handleClose}>X</span>
        </div>
      )}
      <img src="/assets/images/ia/ia.png" alt="IA" className="floating-image" />
    </div>
  );
};

export default FloatingButton;
