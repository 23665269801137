// reducer.js
import { SET_MENU_SECTION } from './actionTypes';

const initialState = {
  menuSection: 0
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MENU_SECTION:
      return {
        ...state,
        menuSection: action.payload
      };
    default:
      return state;
  }
};

export default menuReducer;