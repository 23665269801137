import React from 'react'
import Progress from './Progress'

const CardComisiones = ({tipo}) => {
  return (
    <div className='p-2 col-12'>
      <div className='card cardRounded shadow border-0 cardRedondoMisRegistros d-flex flex-column justiy-content-between align-items-center col-12'>
         <div className='col-12 d-flex'>
            <div className='flex-column col-5 d-flex justify-content-center align-items-center'>
                <img src='/assets/images/plataforma/avatar.svg' style={{marginTop: "-50px"}}></img>
                <span className='text-center camptonMedium fs-20'>Gloria Inés Martinez</span>
            </div>
            <div className='d-flex flex-column col-7 justify-content-between p-0 cardComisiones'>

                <div className='d-flex  justify-content-between p-2'>
                    <div>
                        <span className='camptonLight fs-20'>Fecha de inicio: </span>
                        <span className='camptonMedium fs-20'>28/08/22</span>
                    </div>
                    <div>
                        <span className='camptonLight fs-20'>Fecha de Cierre: </span>
                        <span className='camptonMedium fs-20'>19/09/22</span>
                    </div>
                </div>

                <div className='d-flex  justify-content-between p-2'>
                    <div>
                        <span className='camptonLight fs-20'>Crédito adquirido: </span>
                        <span className='camptonMedium fs-20'>$15.000</span>
                    </div>
                    <div>
                        <span className='camptonLight fs-20'>Cantidad de pagos: </span>
                        <span className='camptonMedium fs-20'>40</span>
                    </div>
                </div>
            </div>
        </div>  

            {/* aqui va la barra de progreso */}
        <div className='col-12'>
             <Progress/>
        </div>

        
            <div className='col-10 p-5 mb-5'>
            <div className='d-flex col-12 justify-content-between'>
                    <div>
                        <span className='camptonLight fs-20'>Valor del pago: </span>
                        <span className='camptonMedium fs-20'>$825</span>
                    </div>
                    <div>
                        <span className='camptonLight fs-20'>Saldo en mora: </span>
                        <span className='camptonMedium fs-20'>$2.475</span>
                    </div>
                </div>

                <div className='d-flex  justify-content-between mt-3'>
                    <div>
                        <span className='camptonLight fs-20'>Frecuencia de pago: </span>
                        <span className='camptonMedium fs-20'>Diaria</span>
                    </div>
                    <div>
                        <span className='camptonLight fs-20'>Saldo pendiente: </span>
                        <span className='camptonMedium fs-20'>$8.250</span>
                    </div>
                </div>
            </div>

           <div className='col-12 d-flex justify-content-end'>
           <div  className={`${tipo === 1 ? 'cardComisionesReverse' : 'cardComisionesReverseRed'} col-4 d-flex justify-content-center flex-column text-center`}>
              <span className='camptonMedium text-white fs-20'> {tipo === 1 ? "CRÉDITO AL DÍA" : "REESTRUCTURADO" } {tipo === 1 ? "" : <img src='/assets/images/plataforma/ojito.svg' className='ps-3'></img> }</span>
              <div className=' mb-2 d-flex justify-content-center align-items-center'>
                  <span className='camptonLight fs-20'>{tipo === 1 ? "Valor a comisionar:" : "Valor a comisionar" }   </span>
                  <span className='camptonMedium ps-3 fs-15'> $1.500  {tipo === 1 ? "" : <img src='/assets/images/plataforma/arrow.svg' className='ps-3'></img> }</span>
              </div>
           </div>
           </div>



      </div>
      <div></div>
      <div></div>

    </div>
  )
}

export default CardComisiones