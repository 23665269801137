import React from 'react'
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    const token = "tu-token-aqui"; // Deberías obtener este valor de tu servidor
    localStorage.setItem("token", token);
    navigate('/dashboard'); // Esto redirigirá a los usuarios a la página del dashboard
  }

  return (
    <button onClick={handleLogin}>Entrar</button>
  )
}

export default HomePage;
