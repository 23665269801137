import React from 'react'
import Paginacion from '../../paginacion/Paginacion'
import SearchComisiones from '../comisiones/SearchComisiones'
import Card from './Card'

const MisSolicitudes = ({setCreditRequests, creditRequests,setFilters,filters}) => {
    return (
      <div className='cards-container col-12 p-4'> 
          <SearchComisiones setFilters={setFilters} filters={filters}/>
          {creditRequests.map((request)=>( 
            <div className='p-2 col-12 col-lg-6' key={request.id}>
            <div className='card shadow border-0 cardRedondoMisRegistros d-flex flex-column justiy-content-center align-items-center' style={{borderRadius: "15px"}}>
                    <span className='camptonMedium fs-20 mt-3'>{request.client.name}</span>
    
    
                    <div className='d-flex flex-row justify-content-between col-11 mt-3'>
                        <div className='d-flex'>
                            <img src='/assets/images/plataforma/Phone.svg' className='d-flex mt-1' style={{width: ".8vw", height: ".8vw" }}></img>
                            <span className='camptonLight fs-15 ps-1'>{request.client.phone}</span>
                        </div>
                        <div className='d-flex'>
                            <i></i>
                            <img src='/assets/images/plataforma/ubicacion.svg' className='d-flex mt-1' style={{width: ".8vw", height: ".8vw" }}></img>
                            <span className='camptonLight fs-15 ps-1'>{request.client.company.address} {request.client.company.floor_number} C.P {request.client.company.postal_code}</span>
                        </div>
                    </div>
    
                    <div className='d-flex flex-row justify-content-between  col-11 mt-3'>
                        <div className='d-flex flex-column'>
                            <span className='camptonMedium fs-15'>Status</span>
                            <span className='camptonLight fs-15'>{request.status.name}</span>
                        </div>
                        <div className='d-flex flex-column'>
                            <span className='camptonMedium fs-15'>Monto que solicita</span>
                            <span className='camptonLight fs-15'>${Number(request.amount).toLocaleString('en-US')}</span>
                        </div>
                    </div>
    
                    <div className='d-flex flex-row justify-content-between col-11 mt-3'>
                        <div className='d-flex flex-column'>
                            <span className='camptonMedium fs-15'>Posible Comision</span>
                            <span className='camptonLight fs-15'><span className='camptonLight fs-15'>${Number(1).toLocaleString('en-US')}</span></span>
                        </div>
                        <div className='d-flex flex-column'>
                            <span className='camptonMedium fs-15'>Registro Visuales</span>
                            <div className='d-flex'>
                                <img src='/assets/images/plataforma/file.svg' className='d-flex mt-1' style={{width: "1vw", height: "1vw" }}></img>
                                <img src='/assets/images/plataforma/shop.svg' className='d-flex mt-1' style={{width: "1vw", height: "1vw" }}></img>
                            </div>
                        </div>
                    </div>
    
                    <div className='col-12  d-flex justify-content-end align-items-end'>
                        <div className='col-10  borderRoundedInscripcion mt-3 fechaInscripcion'>
                            <span className="text-white  camptonLight fw-bold fs-15 px-2 ps-2" >Fecha de inscripcion: </span>
                            <span className='fs-20 text-white camptonLight fw-bold ps-2 px-2'>{request.created_at}</span>
                        </div>
                    </div>
    
    
            
                </div>
    
    
                <div></div>
            </div>
          ))}
          
          <Paginacion/>
      </div>
    )
  }
  

export default MisSolicitudes