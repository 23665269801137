import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import TitlePrincipalCredito from '../titles/TitlePrincipalCredito';
import * as Yup from "yup";
import { useFormik } from "formik";

const Hablanos = ({ siguientePaso, client, setClient, handleChange }) => {
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: client,
        validationSchema: Yup.object({
            name: Yup.string().required('El nombre es obligatorio'),
            phone: Yup.string().required('El número telefónico es obligatorio'),
            gender: Yup.string().required('El género es obligatorio'),
            birthdate: Yup.string().required('La fecha de nacimiento es obligatoria'),
            curp: Yup.string()
            .length(18, 'La CURP debe tener exactamente 18 caracteres')
            .required('La CURP es obligatoria')
            /*.test('curp-match', 'La CURP no coincide con las iniciales del nombre y la fecha de nacimiento', function (value) {
                const { name, birthdate } = this.parent;
                const expectedCURP = generateExpectedCURP(name, birthdate);

                // Compare the entered CURP with the expected CURP
                return value === expectedCURP;
            })*/,
            identification_document: Yup.string().required('Es Necesario seleccionar un documento')
        }),
        onSubmit: async values => {
            setClient(values)
            siguientePaso();
        },
    });

    return (
        <div className='col-12 d-flex justify-content-center'>
            
            <div className='d-flex col-11 col-md-7 flex-column flex-lg-row justify-content-center'>
                <div className='px-2'>
                    <TitlePrincipalCredito title={"Hablanos de ti"} />
                    <img className='mt-0 mt-lg-5 d-none d-lg-flex' src='/assets/images/solicitarCredito/hablanos.svg' />
                </div>
                <div>
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            validation.handleSubmit(); 
                            return false;
                        }}
                    >
                        <div className=' mb-5 card cardRounded border-0 d-flex col-12 justify-content-center p-0 p-md-3 flex-column '>
                            <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3 mt-md-2'>
                                <label className='camptonMedium'>¿Cuál es tu nombre completo?</label>
                                <input
                                    name="name"
                                    placeholder='Escribe tu Nombre completo'
                                    className={`inputContactanos camptonLight ${validation.touched.name && validation.errors.name ? 'has-error' : ''}`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.name || ""}
                                />
                                {validation.touched.name && validation.errors.name && (
                                    <div className='error-text' style={{color:'red'}}>{validation.errors.name}</div>
                                )}
                            </div>
                            <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3'>
                                <label className='camptonMedium'>¿Cuál es tu número telefónico?</label>
                                <input
                                    name="phone"
                                    placeholder='Escribe tu numero telefonico'
                                    className={`inputContactanos camptonLight ${validation.touched.phone && validation.errors.phone ? 'has-error' : ''}`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.phone || ""}
                                />
                                {validation.touched.phone && validation.errors.phone && (
                                    <div className='error-text' style={{color:'red'}}>{validation.errors.phone}</div>
                                )}
                            </div>
                            <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3'>
                                <label className=' camptonMedium'>Genero</label>
                                <div className='mt-2 col-12 d-flex justify-content-center align-items-center flex-column flex-md-row'>
                                    <div className='col-10 col-md-4 p-2'>
                                        <button
                                            className={validation.values.gender === 'mujer' ? 'opcion-genero-activo col-12 camptonMedium' : 'opcion-genero col-12 camptonMedium'}
                                            onClick={() => validation.setFieldValue('gender', 'mujer')}
                                        >
                                            Mujer
                                        </button>
                                    </div>
                                    <div className='col-10 col-md-4 p-2'>
                                        <button
                                            className={validation.values.gender === 'hombre' ? 'opcion-genero-activo col-12 mt-3 mt-md-0 camptonMedium' : 'opcion-genero col-12 mt-3 mt-md-0 camptonMedium'}
                                            onClick={() => validation.setFieldValue('gender', 'hombre')}
                                        >
                                            Hombre
                                        </button>
                                    </div>
                                    <div className='col-10 col-md-4 p-2'>
                                        <button
                                            className={validation.values.gender === 'otro' ? 'opcion-genero-activo col-12 mt-3 mt-md-0 camptonMedium' : 'opcion-genero col-12 mt-3 mt-md-0 camptonMedium'}
                                            onClick={() => validation.setFieldValue('gender', 'otro')}
                                        >
                                            Otro
                                        </button>
                                    </div>
                                </div>
                                {validation.touched.gender && validation.errors.gender && (
                                    <div className='error-text' style={{color:'red'}}>{validation.errors.gender}</div>
                                )}
                            </div>
                            <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3'>
                                <label className='camptonMedium'>Fecha de Nacimiento</label>
                                <input
                                    name="birthdate"
                                    type='date'
                                    className={`inputContactanos camptonLight ${validation.touched.birthdate && validation.errors.birthdate ? 'has-error' : ''}`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.birthdate || ""}
                                />
                                {validation.touched.birthdate && validation.errors.birthdate && (
                                    <div className='error-text' style={{color:'red'}}>{validation.errors.birthdate}</div>
                                )}
                            </div>
                            <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3'>
                                <label className='camptonMedium'>CURP</label>
                                <input
                                    name="curp"
                                    placeholder='Escribe tu CURP'
                                    className={`inputContactanos camptonLight ${validation.touched.curp && validation.errors.curp ? 'has-error' : ''}`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.curp || ""}
                                />
                                {validation.touched.curp && validation.errors.curp && (
                                    <div className='error-text' style={{color:'red'}}>{validation.errors.curp}</div>
                                )}
                            </div>

                            <div className='col-11 col-md-12 text-end mt-3 border-bottom'>
                            </div>


                            <div className='d-flex flex-column col-10 col-md-12 ps-md-0 mt-md-0'>
                                <label className='mt-4 mb-3 camptonMedium'>Selecciona un documento de identificación para presentar a nuestro asesor</label>
                                <div>
                                    <div className='d-flex flex-column  ps-4'>
                                        <div className='d-flex col-12 col-md-10 justify-content-center align-items-center flex-row mb-4'>
                                            <div className={`d-flex flex-column ps-2 col-5 col-md-4 mb-3 mb-lg-0 mb-xxl-0`}  >
                                                <div className={validation.values.identification_document ==  'licencia' ? `opcion-negocio-activo col-12 mt-0 mt-lg-5 card border-0 shadow p-4 p-lg-3 d-flex flex-column align-items-center`:'opcion-negocio mt-0 mt-lg-5 card border-0 shadow p-4 p-lg-3 col-12 d-flex flex-column align-items-center'}>
                                                    <img src='/assets/images/QuienesSomos/icono-contactanos.png' width="40%" className="" onClick={() => validation.setFieldValue('identification_document', 'licencia')}></img>
                                                    <span className=' d-flex justify-content-center align-items-center fs-15 text-center col-12 camptonLight text-black'>Licencia de conducir</span>
                                                </div>
                                            </div>
                                            
                                            <div className={`d-flex flex-column ps-2 col-5 col-md-4 mb-3 mb-lg-0 mb-xxl-0`}  >
                                                <div className={validation.values.identification_document ==  'pasaporte' ? `opcion-negocio-activo col-12 mt-0 mt-lg-5 card border-0 shadow p-4 p-lg-3 d-flex flex-column align-items-center`:'opcion-negocio mt-0 mt-lg-5 card border-0 shadow p-4 p-lg-3 col-12 d-flex flex-column align-items-center'}>
                                                    <img src='/assets/images/QuienesSomos/icono-contactanos.png' width="40%" className="" onClick={() => validation.setFieldValue('identification_document', 'pasaporte')}></img>
                                                    <span className=' d-flex justify-content-center align-items-center fs-15 text-center col-12 camptonLight text-black'>Pasaporte</span>
                                                </div>
                                            </div>
                                            <div className={`d-flex flex-column ps-2 col-5 col-md-4 mb-3 mb-lg-0 mb-xxl-0`}  >
                                                <div className={validation.values.identification_document ==  'ine' ? `opcion-negocio-activo col-12 mt-0 mt-lg-5 card border-0 shadow p-4 p-lg-3 d-flex flex-column align-items-center`:'opcion-negocio mt-0 mt-lg-5 card border-0 shadow p-4 p-lg-3 col-12 d-flex flex-column align-items-center'}>
                                                    <img src='/assets/images/QuienesSomos/icono-contactanos.png' width="40%" className="" onClick={() => validation.setFieldValue('identification_document', 'ine')}></img>
                                                    <span className=' d-flex justify-content-center align-items-center fs-15 text-center col-12 camptonLight text-black'>IFE-INE</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>



                            <div className='mb-2 mt-2 col-12 d-flex justify-content-center'>
                                <button type='submit' className='custom-button-primary camptonLight fw-bold w-auto ps-3 px-3'>Siguiente</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Hablanos