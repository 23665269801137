import React from 'react'

const SubtitleTercero = ({subtitle}) => {
  return (
    <div className='d-flex justify-content-start align-items-center col-10'>
        <span className='text-black p-2 text-start d-flex fs-25'>
            {subtitle}
        </span>
    </div>
  )
}

export default SubtitleTercero