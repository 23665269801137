import React, { useState } from 'react'
import { FaEye, FaDownload } from "react-icons/fa";
import { Link } from 'react-router-dom';

const Descarga = ({setSolicitar, solicitar}) => {

    const [showPreview, setShowPreview] = useState(false);



    const handleClick = () => {
        setShowPreview(false);
        setSolicitar(false);
      }

  return (

    <div className='card cardRounded border-0 col-12 d-flex  flex-lg-row-reverse'>


    <div className='col-12 col-lg-8 d-flex jusitfy-content-center align-items-center flex-column p-3' style={{padding: '0px' }}>
        {/* <img src={image} alt='Preview' className='col-12  mt-5 mb-5 d-flex jusitfy-content-center align-items-center' /> */}
        <form className="col-12 col-lg-9">


                        <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3 mt-md-3'>
                            <label className='camptonMedium text-center'>Encontramos tu factura, estará disponible hasta 24/10/2022</label>
                        </div>
                        <div className='d-flex flex-column flex-lg-row col-12 col-md-12 ps-0 ps-md-0  mt-5'>
                            <button className='button-seleccion-fecha w-auto camptonLight'>
                                Factura del crédito #858493928
                            </button>
                            <button className='custom-button-primary w-auto px-3 ps-3 col-lg-2 col-12'>
                                <FaEye />{/* // Ojito (eye icon) */}
                            </button>
                            <button className='custom-button-primary w-auto px-3 ps-3 col-lg-2 col-12'>
                                {/* // Flecha de descarga (download icon) */}
                                <FaDownload /> 
                            </button>
                        </div>

                       

        </form>
        <Link to={"/factura"}>
        <button onClick={handleClick} className="custom-button-primary  mt-5 mb-4 col-12 col-lg-12 w-auto px-3 ps-3 camptonLight fw-bold ">Buscar otra factura</button>
        </Link>
    </div>
    <div className=' border-0 col-6 mt-5 d-flex jusitfy-content-start align-items-start flex-column p-5 p-lg-3 d-none d-lg-flex' style={{padding: '0px' }}>
        <div className="mt-3">
            <h1 className="color-primary fw-bold border-primary-home p-4 col-12 text-start">
                <span className=" mt-4 mb-4 fs-40">
                Facturación en línea
                </span>
            </h1>
        </div>


    </div>
        {/* <img src="/assets/images/aliados/unidos.png" alt='Preview' className='col-12  mt-5 mb-5 d-flex jusitfy-content-center align-items-center' /> */}
</div>
  )
}

export default Descarga