import React from 'react'
import { Link } from 'react-router-dom'
import AliadoTandgo from '../components/aliados/AliadoTandgo'
import InputNewsletter from '../components/formulario/InputNewsletter'
import Navbar from '../components/navbar/Navbar'
import Confianza from '../components/QuienesSomos/Confianza'
import Contactanos from '../components/QuienesSomos/Contactanos'
import MejorAliado from '../components/QuienesSomos/MejorAliado'
import QuienesSomosHome from '../components/QuienesSomos/QuienesSomosHome'
import SliderCrecimiento from '../components/slider/SliderCrecimiento'
import SliderExitos from '../components/slider/SliderExitos'
import SliderHome from '../components/slider/SliderHome'
import SliderProceso from '../components/slider/SliderProceso'
import TitlePrincipal from '../components/titles/TitlePrincipal'
import TitleSecundario from '../components/titles/TitleSecundario'
import TitleTercero from '../components/titles/TitleTercero'

const QuienesSomos = () => {
  return (
    <>
    <div style={{backgroundImage: 'url(/assets/images/QuienesSomos/fondoMorado.png)', backgroundSize: '100% 100%', minHeight: '40em', maxHeight: "100em", height: "60em"}}>
       <Navbar/>
       {/* <TitlePrincipal title={"Somos tu aliado para"}/> */}
       <QuienesSomosHome/>
    </div>
    <div className=''>
      <Confianza/>
      <MejorAliado/>
      <Contactanos/>
    </div>
   </>
  )
}

export default QuienesSomos