import React, { useState } from 'react';

const Formulario = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <div className=''>
        <div className="d-flex flex-column align-items-center justify-content-center card border-0 shadow mb-5 cardRounded">
            <span className='fs-30 fw-bold mt-5 text-center'>Aplica ahora el llenado del formulario</span>
            <div className="d-flex flex-wrap flex-column flex-lg-row col-12 justify-content-center">
                <div className="d-flex flex-column col-lg-5 px-lg-4">
                    <label className='mt-4 camptonLight'>Nombres</label>
                    <input placeholder='Escribe tu nombre'  className='inputFormulario'></input>
                </div>

                <div className="d-flex flex-column col-lg-5 ps-lg-4">
                    <label className='mt-4 camptonLight'>Apellidos</label>
                    <input placeholder='Escribe tus apellidos'  className='inputFormulario'></input>
                </div>

                <div className="d-flex flex-column col-lg-5 px-lg-4">
                    <label className='mt-4 camptonLight'>Telefono</label>
                    <input placeholder='Escribe tu telefono' className='mb-lg-4 inputFormulario'></input>
                </div>

                <div className="d-flex flex-column col-lg-5 ps-lg-4">
                    <label className='mt-4 camptonLight'>Email</label>
                    <input placeholder='Escribe tu email' className='inputFormulario'></input>
                </div>
            </div>


            {/* en este div va a ir la carga del documento */}
            <div className="camptonLight mb-5 border d-flex align-items-center justify-content-center p-3 my-3 col-12 col-lg-11" style={{minHeight: '200px', cursor: 'pointer'}} onClick={() => document.getElementById("hiddenFileInput").click()}>
                {file ? (
                  <p className="m-0">{file.name}</p>
                ) : (
                  <p className="m-0">Click aquí para subir un documento</p>
                )}
                <input id="hiddenFileInput" type="file" onChange={handleFileChange} style={{display: 'none'}} />
            </div>

            <button className='camptonLight fw-bold custom-button-primary w-auto d-flex justify-content-center alig-items-center mb-5 ps-5 px-5'>Aplicar a este puesto</button>
        </div>
    </div>
  )
}

export default Formulario;
