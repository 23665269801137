import React, { useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import { StreetViewPanorama, GoogleMap, LoadScript } from '@react-google-maps/api';
import Map from '../../../maps/Map'
const StreetView = ({ avanzar,solicitud,setSolicitud }) => {
  const [capturedImage, setCapturedImage] = useState(null);
  const streetViewRef = useRef(null);

  const handleCapture = () => {
    setCapturedImage(`https://maps.googleapis.com/maps/api/streetview?size=${400}x${400}&location=${solicitud.lat},${solicitud.lng}&fov=${90}&heading=${100}&pitch=${10}&key=AIzaSyBd2Yji4ITZox7e1giZIWQ5pIyJvfJ2aTQ`);

  };
  
  const handleCancel = () => {
    setCapturedImage(null);
  };

  const streetViewOptions = {
    position: { lat: solicitud.lat, lng: solicitud.lng }, // Coordenadas de ejemplo (Nueva York)
    pov: { heading: 100, pitch: 10 }, // Orientación y ángulo de inclinación del Street View
    visible: true, // Hace que el Street View sea visible
    enableCloseButton: true, // Muestra el botón para cerrar el Street View
  };

  const saveCapture = () =>{
    fetch(capturedImage)
  .then((response) => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.blob();
  })
  .then((blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      
      setSolicitud({...solicitud,fachadaData:base64data})
      
      // Haz lo que necesites con la imagen en base64
    };
  })
  .catch((error) => {
    console.error('Hubo un problema con la solicitud fetch:', error);
  });
  avanzar()
  }

  return (
    <div className='border-0 col-12 px-2'>
      <div className='border-0 p-2 d-flex flex-row col-12 mb-5'>

        
        <div className="d-flex flex-column shadow borderMapa align-items-center col-4">

            <span className='camptonLight p-3 mt-3 fs-20'>Busca el mejor ángulo en Google Street.</span>
            <span className='camptonLight p-3 fs-20'>Encaja dentro del recuadro negro la fachada del negocio del cliente que vas a registrar</span>
            
          { !capturedImage && 
            <button 
              className='mt-3 mb-3 me-3 w-auto custom-button-primary shadow cardRounded camptonLight d-flex flex-column justify-content-center align-items-center'
              onClick={handleCapture}
            >
              <span className='camptonLight ps-3 px-3 fs-20'>Capturar fachada</span>
            </button>
          }
        </div>
        <div className='col-8' ref={streetViewRef}>
            
          { !capturedImage &&
            <Map solicitud={solicitud} setSolicitud={setSolicitud} streetViewMap={true}  />
          }
          { capturedImage &&
            <img src={capturedImage} alt="Street View" className='roundedMapa col-12' />
          }
        </div>
      </div>

        { capturedImage &&
            <div className='d-flex justify-content-end align-items-center col-9 mb-5'>
              <div className='d-flex justify-content-between align-items-center col-3 mb-5'>
              <img src='/assets/images/plataforma/delete.svg' className='d-flex mt-1' onClick={handleCancel} style={{width: "2.5vw", height: "2.5vw", cursor: "pointer" }}>
              </img>
              <img src='/assets/images/plataforma/submit.svg' className='d-flex mt-1' onClick={() => saveCapture()} style={{width: "2.5vw", height: "2.5vw", cursor: "pointer" }}>
              </img>
              </div>
            </div>
          }

    </div>
  );
};

export default StreetView;
