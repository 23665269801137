import React, { useState } from "react";
import Disculpa from "../Modales/SolicitarCredito/Disculpa";

const Negocio = ({siguientePaso}) => {

    const [activeOption, setActiveOption] = useState('');
    const [showModal, setShowModal] = useState(false);

    const handleContinuarClick = () => {
        if (activeOption === 'no') {
            setShowModal(true);
        } else if (activeOption === 'si') {
            siguientePaso();
        }
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }


    // cambio de imagen

    // Función para cambiar la imagen cuando se selecciona una opción
    function changeImage(option) {
        const normalImage = document.getElementById(`${option}-image-normal`);
        const hoverImage = document.getElementById(`${option}-image-hover`);
        
        // Oculta la imagen normal y muestra la imagen de hover
        normalImage.style.display = 'none';
        hoverImage.style.display = 'block';
    }

    // Función para agregar eventos una vez que el DOM esté listo
    document.addEventListener('DOMContentLoaded', function () {
        const siCard = document.getElementById('si-card');
        const noCard = document.getElementById('no-card');

        // Verifica si los elementos existen antes de agregar eventos
        if (siCard) {
            siCard.addEventListener('click', () => {
                setActiveOption('si'); // Cambia la opción activa
                changeImage('si'); // Cambia la imagen
            });
        }

        if (noCard) {
            noCard.addEventListener('click', () => {
                setActiveOption('no'); // Cambia la opción activa
                changeImage('no'); // Cambia la imagen
            });
        }
    });


    return (
        <div className="col-12 d-flex justify-content-center align-items-center">
            <div className="col-10 d-flex justify-content-center align-items-center flex-column">

                <h1 className="d-flex d-lg-none  mb-2 mb-lg-5 color-primary fw-bold cuadro-negocio border-primary-home p-3 col-10 text-center justify-content-center">
                    <span className="p-3  fs-30 camptonMedium">
                    ¿Tienes un negocio propio?
                    </span>
                </h1>

                <div className="card-negocio border-0 shadow col-12 col-md-6 d-flex flex-row ">
                    <div className="col-12 mt-5">

                        <h1 className="d-none d-lg-flex text-start mb-5 color-primary fw-bold position-absolute cuadro-negocio">
                            <span className=" border-primary-home col-12 fs-30 p-3 p-lg-5 camptonMedium">
                            ¿Tienes un negocio propio?
                            </span>
                        </h1>



                        <div className='mt-0 mt-lg-5 d-flex col-12 col-lg-6 justify-content-center align-items-center mt-md-4 mb-md-4 flex-row'>
                            <div className='mt-0 mt-lg-5 d-flex flex-column col-5 col-md-4 px-2 mb-3 mb-lg-0 mb-xxl-0 justify-content-center align-items-center'>
                                <div 
                                    className={activeOption === 'si' ? 'opcion-negocio-activo col-12 mt-0 mt-lg-5 card border-0 shadow p-4 p-lg-3 d-flex flex-column align-items-center' 
                                    : 'opcion-negocio mt-0 mt-lg-5 card border-0 shadow p-4 p-lg-3 col-12 d-flex flex-column align-items-center'} 
                                    onClick={() => setActiveOption('si')}
                                    style={{ borderRadius: "20px" }}
                                >
                                    <span className=' d-flex justify-content-center align-items-center camptonLight'>Si</span>
                                    <div className="image-container">
                                        <img src='/assets/images/solicitarCredito/siNormal.svg' className="normal-image mt-2" width="70%" alt="Normal" />
                                        <img src='/assets/images/solicitarCredito/siHover.svg' className="hover-image mt-2" width="70%" alt="Hover" />
                                    </div>
                                </div>
                            </div>

                            <div className='mt-0 mt-lg-5 d-flex flex-column col-5 col-md-4 px-2 mb-3 mb-lg-0 mb-xxl-0 justify-content-center align-items-center'>
                                <div 
                                    className={activeOption === 'no' ? 'col-12 opcion-negocio-activo mt-0 mt-lg-5 card border-0 shadow p-4 p-lg-3 d-flex flex-column align-items-center' 
                                    : 'opcion-negocio mt-0 mt-lg-5 card border-0 col-12 shadow p-4 p-lg-3 d-flex flex-column align-items-center'} 
                                    onClick={() => setActiveOption('no')}
                                    style={{ borderRadius: "20px" }}
                                >
                                    <span className='d-flex justify-content-center align-items-center camptonLight'>No</span>
                                    <div className="image-container">
                                        <img src='/assets/images/solicitarCredito/noNormal.svg' className="normal-image mt-2" width="40%" alt="Normal" />
                                        <img src='/assets/images/solicitarCredito/noHover.svg' className="hover-image mt-2" width="40%" alt="Hover" />
                                    </div>
                                </div>
                            </div>
                        </div>





                        <div className='mb-4 mt-5 col-12 col-lg-6 d-flex justify-content-center'>
                            <button className='custom-button-primary camptonLight fw-bold' onClick={handleContinuarClick}>Continuar</button>
                        </div>

                    </div>
                </div>

                <Disculpa isOpen={showModal} onClose={handleCloseModal} />

                <div className=" d-none d-lg-flex col-6 position-absolute img-negocio">
                    <div className='mt-0 ml-lg-5 mt-md-5 d-flex justify-content-start '>
                        <img src='/assets/images/SolicitarCredito/negocioPropio.svg' className='img-custom-quienes'/>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Negocio;
