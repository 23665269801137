import React from 'react'
import TitleSecundario from '../../titles/TitleSecundario'
import InputCiudades from '../InputCiudades'
import InputSearch from '../InputSearch'

const OfertasInput = () => {
  return (

        <div className='card col-12 border-0 shadow cardRounded'>
            <span className=' ps-3 px-3 text-center camptonBold my-5 fw-bold color-secondary fs-50 col-12 text-center d-flex justify-content-center align-items-center'>Ofertas Laborales</span>
            
            <div className='col-12 d-flex flex-column flex-lg-row justify-content-center align-items-center'>
                <div className="unique-search-container d-flex justify-content-center align-items-center col-12 mt-4 mb-4">
                <div className="unique-search-input-group d-flex justify-content-center align-items-center col-lg-5 col-5">
                    <input
                    type="text"
                    className="unique-search-input p-3 col-12 border"
                    placeholder="Todas las ciudades"
                    aria-label="Buscar"
                    />
                    <div className="unique-search-inner-icon-wrap">
                        <i className="fa fa-chevron-down"></i>
                    </div>
                </div>
                <div className="unique-search-input-group d-flex justify-content-center align-items-center col-lg-5 col-5 ps-3">
                    <input
                    type="text"
                    className="unique-search-input p-3 col-12 border"
                    placeholder="Buscar"
                    aria-label="Buscar"
                    />
                    <div className="unique-search-inner-icon-wrap">
                    <span className="unique-search-inner-icon">
                        <i className="fa fa-search"></i>
                    </span>
                    </div>
                </div>
                <div className="unique-search-external-icon-wrap">
                    <span className="unique-search-circle-icon">
                    <i className="fa fa-ellipsis-h"></i>
                    </span>
                </div>
                </div>
            </div>


            
        </div>
  )
}

export default OfertasInput