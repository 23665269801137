import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import InputNewsletter from '../components/formulario/InputNewsletter'
import Progreso from '../components/lineaProgreso/Progreso'
import ProgresoBarra from '../components/lineaProgreso/ProgresoBarra'
import Navbar from '../components/navbar/Navbar'
import Contactanos from '../components/QuienesSomos/Contactanos'
import Codigo from '../components/SolicitarCredito/Codigo'
import EscaneoINE from '../components/SolicitarCredito/EscaneoINE'
import EscaneoRostro from '../components/SolicitarCredito/EscaneoRostro'
import Hablanos from '../components/SolicitarCredito/Hablanos'
import HablanosNegocio from '../components/SolicitarCredito/HablanosNegocio'
import Negocio from '../components/SolicitarCredito/Negocio'
import SeleccionFecha from '../components/SolicitarCredito/SeleccionFecha'
import Ubicacion from '../components/SolicitarCredito/Ubicacion'
import VisitaProgramada from '../components/SolicitarCredito/VisitaProgramada'
import TitleTercero from '../components/titles/TitleTercero'
import {ToastContainerHelper} from '../helpers/alerts/alertHelper';

const SolicitarCredito = () => {

    const [paso, setPaso] = useState(0); // inicia en el paso 0
    const [client, setClient] = useState({
        id: null,
        birthdate: '',
        gender: '',
        curp: '',
        identification_document: '',
        address: '',
        floor_number: '',
        interior_number: '',
        between_streets: '',
        postal_code: '',
        state: '',
        country: '',
        aditional_references: '',
        imgDoc:null,
        imgDocText:'',
        photo:null,
        photoScan:null,
        step:2,
        identification_data:''
    });
    const handleChange = () => {
        console.log('cambio el usuario')
    }
    const siguientePaso = () => setPaso(pasoPrevio => pasoPrevio + 1); // función para ir al siguiente paso
    const pasoAnterior = () => setPaso(pasoPrevio => Math.max(pasoPrevio - 1, 0)); // función para ir al paso anterior


    return (
        <>
            <div style={{ backgroundImage: 'url(/assets/images/fondoMorado.png)', backgroundSize: '100% 100%', minHeight: '100%', maxHeight: "180em", height: paso === 0 ? "70em" : (paso === 1 ? "80em" : (paso === 2 ? "70em" : (paso === 3 ? "70em" : "60em"))) }}>
                <Navbar />
                <ToastContainerHelper />
                <div>
                    <div className="desktop-only">
                        <Progreso paso={paso} />
                    </div>
                    <div className="mobile-only">
                        <ProgresoBarra paso={paso} />
                    </div>
                </div>

                {/* <Progreso paso={paso}/> */}

                {paso === 0 && <Negocio handleChange={handleChange} siguientePaso={siguientePaso} />} {/* mostrar el componente Negocio solo si el paso es 0 y pasar la función siguientePaso */}

                {paso === 1 && <Hablanos handleChange={handleChange} client={client} setClient={setClient} siguientePaso={siguientePaso} pasoAnterior={pasoAnterior} />} {/* mostrar el componente Hablanos solo si el paso es 1 y pasar las funciones siguientePaso y pasoAnterior */}

                {paso === 2 && <Ubicacion handleChange={handleChange} client={client} setClient={setClient} siguientePaso={siguientePaso} pasoAnterior={pasoAnterior} />} {/* mostrar el componente Hablanos solo si el paso es 1 y pasar las funciones siguientePaso y pasoAnterior */}
            </div>
        </>
    )
}

export default SolicitarCredito