import React from 'react'
import { Link } from 'react-router-dom'

const CardEmpleo = () => {
  return (
<>
    

    <div className='col-12 col-lg-5 card border-0 shadow p-4  cardEmpleo espacioCardEmpleo cardRounded'>

    {/* este es mi cuadro azul */}
        <div className="mt-5 col-12 d-flex justify-content-end shadow">
            <h1 className="shadow color-primary fw-bold border-primary-trabajo titleEmpleo d-flex text-start camptonLight fw-bold d-flex justify-content-center align-items-center">
            <span className=" mt-4 mb-4 fs-25">
                Remuneracion: $15,500
            </span>
            </h1>
        </div>
        {/* aqui termina mi cuadro azul */}

            <span className='fw-bold fs-30 camptonLight fw-bold'>Ejecutivo de ventas</span>
            <span className='fs-20 mt-2 mb-2 camptonLight'> <img src='assets/images/bolsaTrabajo/ubicacion.svg'></img> <span className='ps-2'>Monterrey</span></span>
            <span className='fs-20 fw-bold mb-2 camptonLight fw-bold'>Descripción del puesto</span>
            <span className='fs-20 mb-3 camptonLight'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac quam vel justo vehicula venenatis. Suspendisse potenti. Fusce euismod augue at gravida commodo. Nullam vel neque ut tellus elementum scelerisque.</span>
            <span className='fs-20 fw-bold mb-2 camptonLight fw-bold'>Perfil deseado</span>
            <span className='fs-20 mb-3 camptonLight'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac quam vel justo vehicula venenatis. Suspendisse potenti. Fusce euismod augue at gravida commodo. Nullam vel neque ut tellus elementum scelerisque.</span>
            <div className='col-11 col-md-11 text-end mt-3 mb-3'>
                <Link className='fw-bold color-tercero text-decoration-none camptonLight fw-bold fs-20' to={"/bolsa-trabajo/1"}>Ver Detalles</Link>
            </div>
        <div className='card border-0 shadow col-12'>
        </div>
    </div>
</>

  )
}

export default CardEmpleo
