import React from 'react'
import Paginacion from '../../paginacion/Paginacion'
import CardComisiones from './CardComisiones'
import CuadrosPrincipales from './CuadrosPrincipales'
import SearchComisiones from './SearchComisiones'

const MisComisiones = ({meta,setCreditRequests,creditRequests,setFilters,filters}) => {
  return (
    <div className='cards-container col-12 p-4 d-flex flex-column'> 
    <CuadrosPrincipales
            title1={"Créditos Activos"} 
            subtitle1={meta.total} 

            title2={"Valor a comisionar"} 
            subtitle2={"$4.640"} 

            title3={"Ccomisiones de reestructuración"} 
            subtitle3={"$1.250"} 

            title4={"Comision pendiente por reestructuración"} 
            subtitle4={"$1.250"} 
    
    />
    <SearchComisiones setFilters={setFilters} filters={filters}/>

    <div className='p-3 mb-4'>
      <span className='camptonMedium fs-20'>Linea de tiempo de comisiones</span>
    </div>

    <div style={{marginTop: "10px", marginBottom: "30px"}}>
     <CardComisiones tipo={1}/>
    </div>
    <Paginacion/>

    {/* <CardComisiones/>
    <CardComisiones/> */}
    </div>
  )
}

export default MisComisiones