import React from 'react'

const SubtitleBold = ({subtitle}) => {
    return (
      <div className='d-flex justify-content-start align-items-center col-10'>
          <span className='text-black p-2 text-start d-flex fs-30 fw-semibold'>
              {subtitle}
          </span>
      </div>
    )
  }

export default SubtitleBold