import React from 'react'
import "../styles/requisitosCard.css"

const RequisitosCard = () => {
    return (
      <div className='row justify-content-center col-12'>
          <div className='col-md-5 col-sm-12 col-lg-5'>
              <div className='card cardRounded d-flex flex-row align-items-center custom-card shadow border-0 mt-3 p-3'>
                  <img src='/assets/images/aliados/iconoUsuario.png' className='px-3'/>
                  <span className='fs-20 camptonMedium'>Nombre o cómo  te gustaría que te identificáramos</span>
              </div>
              <div className='card cardRounded d-flex flex-row align-items-center custom-card shadow border-0 mt-3 p-3'>
                  <img src='/assets/images/aliados/iconoTelefono.png' className='px-3'/>
                  <span className='fs-20 camptonMedium'>Número telefónico al cual podamos contactarnos</span>
              </div>
          </div>
          <div className='col-md-5 col-sm-12 col-lg-5'>
              <div className='card cardRounded d-flex flex-row align-items-center custom-card shadow border-0 mt-3 p-3'>
                  <img src='/assets/images/aliados/iconoRegistro.png' className='px-3'/>
                  <span className='fs-20 camptonMedium'>Cuenta bancaria en donde recibirás tu comisión*</span>
              </div>
              <div className='card cardRounded d-flex flex-row align-items-center custom-card shadow border-0 mt-3 p-3'>
                  <img src='/assets/images/aliados/iconoAgenda.png' className='px-3'/>
                  <span className='fs-20 camptonMedium'>Registra en nuestra plataforma los referidos con establecimiento comercial conocidos o interesados</span>
              </div>
          </div>
      </div>
    )
  }
  
  export default RequisitosCard