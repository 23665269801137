import { combineReducers } from "redux"
// Authentication
import Login from "./auth/login/reducer"
import MenuReducer from "./plataforma/reducer"


const rootReducer = combineReducers({
  Login,
  MenuReducer
})

export default rootReducer
