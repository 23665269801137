import React from 'react'

const MejorAliado = () => {
    return (

        <>
        <div className='d-flex justify-content-center flex-column flex-lg-row-reverse flex-md-column mb-5' >
            <div className='mt-0 d-flex justify-content-center lado-izquierdo'>
                <img src='/assets/images/aliados/unidos.png' className='img-custom-quienes'/>
            </div>
            <div className='d-flex flex-column justify-content-center align-items-start ps-2 ps-lg-5 w-100 text-white fw-bold'>
            <div className='d-flex flex-column justify-content-center align-items-start ps-3 ps-lg-5 w-100 text-white fw-bold'>
                <h1 className="text-start mt-4 mb-5 color-primary fw-bold  border-primary-home p-3">
                    <span className="p-3 camptonBold">
                        Tu mejor aliado
                    </span>
                </h1>
                <span className="col-10 col-md-10 col-lg-8 fs-30 text-black text-start camptonLight fw-bold">Descripción corta de la historia, misión, visión o por qué es reconocida Tandgo comparado con la competencia.</span>
            </div>
            </div>
        </div>
        </>

      )
    }


export default MejorAliado