import React from 'react'

const Descripcion = () => {
  return (
        <div className='d-flex flex-column col-12 ps-5 mt-5 mt-lg-0'>
            <span className='fs-20 fw-bold mb-2 camptonLight'>Descripción del puesto</span>
            <span className='fs-20 mb-3 camptonLight'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac quam vel justo vehicula venenatis. Suspendisse potenti. Fusce euismod augue at gravida commodo. Nullam vel neque ut tellus elementum scelerisque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac quam vel justo vehicula venenatis. Suspendisse potenti. Fusce euismod augue at gravida commodo. Nullam vel neque ut tellus elementum scelerisque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac quam vel justo vehicula venenatis. Suspendisse potenti. Fusce euismod augue at gravida commodo. Nullam vel neque ut tellus elementum scelerisque.</span>
            <span className='fs-20 fw-bold mb-2 camptonLight'>Perfil deseado</span>
            <span className='fs-20 mb-3 camptonLight'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac quam vel justo vehicula venenatis. Suspendisse potenti. Fusce euismod augue at gravida commodo. Nullam vel neque ut tellus elementum scelerisque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac quam vel justo vehicula venenatis. Suspendisse potenti. Fusce euismod augue at gravida commodo. Nullam vel neque ut tellus elementum scelerisque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac quam vel justo vehicula venenatis. Suspendisse potenti. Fusce euismod augue at gravida commodo. Nullam vel neque ut tellus elementum scelerisque.</span>
        </div>
  )
}

export default Descripcion