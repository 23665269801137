import React, { useRef } from 'react';

const CargaImagenes = ({ avanzar,solicitud,setSolicitud }) => {
  const inputRef = useRef(null);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
  
      if (fileType.startsWith('image/')) {
        // Es una imagen
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const base64data = reader.result;
          setSolicitud({...solicitud,fileTargPagos:base64data})
          // Haz lo que necesites con la imagen en base64
        };
        avanzar(file);
      } else {
        // No es una imagen
        console.error('Por favor, selecciona un archivo de imagen válido.');
      }
    }
  };

  return (
    <div className='d-flex col-12 justify-content-center align-items-start mt-5 mb-5'>
        <div className='d-flex col-10 justify-content-center align-items-start mt-5 mb-5'>
            <input 
              type="file" 
              accept="image/*" 
              style={{ display: "none" }} 
              onChange={handleFileUpload}
              ref={inputRef} 
            />
            <div 
              className='card border-0 cardRounded shadow col-4 p-3 custom-card d-flex justify-content-center text-center' 
              style={{cursor: "pointer"}}
              onClick={() => inputRef.current.click()}
            >
                <img src='/assets/images/plataforma/nuevoRegistro/carga.png' className='custom-image'></img>
                <span className='camptonLight fs-15'>Carga una foto de la tarjeta de pagos actualizada del cliente, por favor que el texto sea legible, de lo contrario será rechazada.</span>
            </div>
            <div 
              className='card border-0 cardRounded shadow col-4 p-3 custom-card d-flex justify-content-center text-center' 
              style={{cursor: "pointer"}}
              onClick={() => inputRef.current.click()}
            >
                <img src='/assets/images/plataforma/nuevoRegistro/carga.png' className='custom-image'></img>
                <span className='camptonLight fs-15'>Carga un screenshot de la aplicación o sistema donde administras el cliente.</span>
            </div>
        </div>
    </div>
  );
};

export default CargaImagenes;
