import React from 'react'
import Paginacion from '../../paginacion/Paginacion'
import SearchComisiones from '../comisiones/SearchComisiones'
import CardProcesoVenta from './CardProcesoVenta'

const EnProcesoVenta = ({setCreditRequests, creditRequests,setFilters,filters}) => {
    return (
      <div className='cards-container col-12 p-4'> 
          <SearchComisiones setFilters={setFilters} filters={filters}/>
          {creditRequests.map((request)=>(
            <CardProcesoVenta 
              request={request}
              key={request.id}
            />
          ))

          }
          
          <Paginacion/>
      </div>
    )
  }
  

export default EnProcesoVenta