import React from 'react';
import '../styles/progreso.css';

function Progreso({ paso, text }) { // Recibe el paso como prop

    return (
      <div className="ProgressBar">
        <div className="progress-container">
          {[...Array(5)].map((_, i) => (
            <React.Fragment key={i}>
              {i % 2 === 0 ?
                <div className="progress-cell">
                  <div className={`progress-step ${i <= paso ? 'active' : ''}`}></div>
                  <div className="step-number" style={{ color: text ? text : 'white' }}>{i / 2 + 1}</div>
                </div> :
                i < 18 && <div className={`progress-bar ${i <= paso ? 'active' : 'inactive'}`}></div>
              }
            </React.Fragment>
          ))}
        </div>
      </div>
    );
}
  
export default Progreso;
