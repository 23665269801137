import React from 'react'

const TablaPagos = () => {
    const datos = [
        { ciclo: "Mayo 2022", monto: "$10.000", comisionesLiquidadas: "20", fecha: "2023/01/01", archivo: "/assets/images/plataforma/pagoComisiones/archivo.png", },
        { ciclo: "Mayo 2022", monto: "$10.000", comisionesLiquidadas: "20", fecha: "2023/01/01", archivo: "/assets/images/plataforma/pagoComisiones/archivo.png", },
        { ciclo: "Mayo 2022", monto: "$10.000", comisionesLiquidadas: "20", fecha: "2023/01/01", archivo: "/assets/images/plataforma/pagoComisiones/archivo.png", },
        { ciclo: "Mayo 2022", monto: "$10.000", comisionesLiquidadas: "20", fecha: "2023/01/01", archivo: "/assets/images/plataforma/pagoComisiones/archivo.png", },
        { ciclo: "Mayo 2022", monto: "$10.000", comisionesLiquidadas: "20", fecha: "2023/01/01", archivo: "/assets/images/plataforma/pagoComisiones/archivo.png", },
        { ciclo: "Mayo 2022", monto: "$10.000", comisionesLiquidadas: "20", fecha: "2023/01/01", archivo: "/assets/images/plataforma/pagoComisiones/archivo.png", },
    
        // ... más datos
      ];
    
      return (
        <div className="table-responsive">
          <table className="table align-middle">
            <thead>
              <tr className='text-center camptonLight fw-bold fs-15'>
                <th scope="col text-center">Código del ciclo</th>
                <th scope="col text-center">Monto liquidado</th>
                <th scope="col text-center">Cantidad de comisiones liquidadas</th>
                <th scope="col text-center">Fecha de liquidación</th>
                <th scope="col text-center">Comprobante</th>
              </tr>
            </thead>
            <tbody>
              {datos.map((fila, index) => (
                <tr className='text-center camptonLight fs-15' key={index}>
                  <td>{fila.ciclo}</td>
                  <td>{fila.monto}</td>
                  <td>{fila.comisionesLiquidadas}</td>
                  <td>{fila.fecha}</td>
                  <td>{fila.comision}<img src={fila.archivo} ></img></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    };

export default TablaPagos