import React, { useState } from 'react'
import AvisoCasiListo from '../Modales/SolicitarCredito/AvisoCasiListo';
import TitlePrincipalCredito from '../titles/TitlePrincipalCredito';
import * as Yup from "yup";
import { useFormik } from "formik";
import { solicitarCredito } from "../../helpers/backend_helper"
import { useNavigate } from 'react-router-dom';

const Ubicacion = ({ siguientePaso, setClient, client }) => {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate()
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: client,
        validationSchema: Yup.object({
            address: Yup.string().required('El nombre es obligatorio'),
            floor_number: Yup.string().required('El número exterior es obligatorio'),
            between_streets: Yup.string().required('Entre calles'),
            country: Yup.string().required('Pais es obligatorio'),
            state: Yup.string().required('Estado es obligatorio'),
            postal_code: Yup.string().required('Codigo postal es necesario'),
            aditional_references: Yup.string().required('Puntos de referencias adicionales'),
            delegation: Yup.string().required('Colonia o delegacion es obligatorio'),
            city: Yup.string().required('Colonia o delegacion es obligatorio')

        }),
        onSubmit: async values => {
            const response = await solicitarCredito(values);
            if (response.status) {
                navigate('/')
            }else{
                console.log(response,'error');
            }
            
        },
    });

    const handleModalClose = () => {
        setShowModal(false);
    }

    return (
        <div className='col-12 d-flex justify-content-center'>
            <div className='d-flex col-11 col-md-6 flex-column flex-lg-row justify-content-center'>
                <div className='px-0 px-md-0 mb-3 mb-md-0'>
                    <span className=' ps-lg-5 camptonMedium spacing-1 text-start col-12 fs-40 d-flex justify-content-center mt-2 mb-2 color-primary fw-bold text-white'>Selecciona la ubicación de tu negocio</span>
                    <img className='mt-0 mt-lg-5 d-none d-lg-flex' src='/assets/images/solicitarCredito/img3.png' />
                </div>
                <div>
                    <form
                        onSubmit={e => {
                            console.log(validation);
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <div className=' mb-5 card cardRounded border-0 d-flex col-12 justify-content-center ps-3 ps-md-4 px-0 px-md-5 p-0 p-md-3 flex-column '>
                            <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3 mt-md-0'>
                                <label className='camptonMedium'>Domicilio</label>
                                <input
                                    name="address"
                                    placeholder='Escribe el domicilio'
                                    className={`inputContactanos camptonLight ${validation.touched.address && validation.errors.address ? 'has-error' : ''}`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.address || ""}
                                />
                                {validation.touched.address && validation.errors.address && (
                                    <div className='error-text' style={{ color: 'red' }}>{validation.errors.address}</div>
                                )}
                            </div>
                            <div className='d-flex flex-column flex-md-row col-12 col-md-12 ps-md-0 mt-3'>
                                <div className='col-11 col-md-6 d-flex flex-column'>
                                    <label className='camptonLight'>Piso</label>
                                    <input
                                        name="floor_number"
                                        placeholder='Escribe el piso'
                                        className={`inputContactanos camptonLight ${validation.touched.floor_number && validation.errors.floor_number ? 'has-error' : ''}`}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.floor_number || ""}
                                    />
                                    {validation.touched.floor_number && validation.errors.floor_number && (
                                        <div className='error-text' style={{ color: 'red' }}>{validation.errors.floor_number}</div>
                                    )}

                                </div>
                                <div className='col-11 col-md-7 d-flex flex-column px-0 px-md-5 mt-3 mt-md-0'>
                                    <label className='camptonLight'>Interior</label>
                                    <input
                                        name="interior_number"
                                        placeholder='Escribe el interior'
                                        className={`inputContactanos camptonLight ${validation.touched.interior_number && validation.errors.interior_number ? 'has-error' : ''}`}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.interior_number || ""}
                                    />
                                    {validation.touched.interior_number && validation.errors.interior_number && (
                                        <div className='error-text' style={{ color: 'red' }}>{validation.errors.interior_number}</div>
                                    )}

                                </div>
                            </div>

                            <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3'>
                                <label className='camptonMedium'>Entrecalles</label>
                                <input
                                    name="between_streets"
                                    placeholder='Escribe entrecalles'
                                    className={`inputContactanos camptonLight ${validation.touched.between_streets && validation.errors.between_streets ? 'has-error' : ''}`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.between_streets || ""}
                                />
                                {validation.touched.between_streets && validation.errors.between_streets && (
                                    <div className='error-text' style={{ color: 'red' }}>{validation.errors.between_streets}</div>
                                )}
                            </div>
                            <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3'>
                                <label className='camptonMedium'>Puntos de referencia</label>
                                <input
                                    name="aditional_references"
                                    placeholder='Escribe el punto de referencia'
                                    className={`inputContactanos camptonLight ${validation.touched.aditional_references && validation.errors.aditional_references ? 'has-error' : ''}`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.aditional_references || ""}
                                />
                                {validation.touched.aditional_references && validation.errors.aditional_references && (
                                    <div className='error-text' style={{ color: 'red' }}>{validation.errors.aditional_references}</div>
                                )}
                            </div>
                            <div className='d-flex flex-column flex-md-row col-12 col-md-12 ps-0 ps-md-0  mt-3'>
                                <div className='col-11 col-md-6 d-flex flex-column'>
                                    <label className='camptonLight'>Colonia/Delegación</label>
                                    <input
                                        name="delegation"
                                        placeholder='Escribe la Colonia'
                                        className={`inputContactanos camptonLight ${validation.touched.delegation && validation.errors.delegation ? 'has-error' : ''}`}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.delegation || ""}
                                    />
                                    {validation.touched.delegation && validation.errors.delegation && (
                                        <div className='error-text' style={{ color: 'red' }}>{validation.errors.delegation}</div>
                                    )}
                                </div>
                                <div className='col-11 col-md-7 d-flex flex-column px-0 px-md-5 mt-3 mt-md-0'>
                                    <label className='camptonLight'>Código Postal</label>
                                    <input
                                        name="postal_code"
                                        placeholder='Escribe el punto de referencia'
                                        className={`inputContactanos camptonLight ${validation.touched.postal_code && validation.errors.postal_code ? 'has-error' : ''}`}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.postal_code || ""}
                                    />
                                    {validation.touched.postal_code && validation.errors.postal_code && (
                                        <div className='error-text' style={{ color: 'red' }}>{validation.errors.postal_code}</div>
                                    )}

                                </div>
                            </div>
                            <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3'>
                                <label className='camptonMedium'>Ciudad</label>
                                <input
                                    name="city"
                                    placeholder='Escribe el punto de referencia'
                                    className={`inputContactanos camptonLight ${validation.touched.city && validation.errors.city ? 'has-error' : ''}`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.city || ""}
                                />
                                {validation.touched.city && validation.errors.city && (
                                    <div className='error-text' style={{ color: 'red' }}>{validation.errors.city}</div>
                                )}
                            </div>
                            <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3'>
                                <label className='camptonMedium'>Estado</label>
                                <input
                                    name="state"
                                    placeholder='Escribe el Estado'
                                    className={`inputContactanos camptonLight ${validation.touched.state && validation.errors.state ? 'has-error' : ''}`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.state || ""}
                                />
                                {validation.touched.state && validation.errors.state && (
                                    <div className='error-text' style={{ color: 'red' }}>{validation.errors.state}</div>
                                )}

                            </div>
                            <div className='d-flex flex-column col-11 col-md-12 ps-0 ps-md-0  mt-3'>
                                <label className='camptonMedium'>País</label>
                                <input
                                    name="country"
                                    placeholder='Escribe el punto de referencia'
                                    className={`inputContactanos camptonLight ${validation.touched.country && validation.errors.country ? 'has-error' : ''}`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.country || ""}
                                />
                                {validation.touched.country && validation.errors.country && (
                                    <div className='error-text' style={{ color: 'red' }}>{validation.errors.country}</div>
                                )}
                            </div>
                            <div className='mb-4 mt-4 col-11 col-md-12 d-flex justify-content-center'>
                                <button
                                    type="submit"
                                    className='custom-button-primary w-auto ps-3 px-3 camptonLight fw-bold'
                                >
                                    Enviar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <AvisoCasiListo isOpen={showModal} onClose={handleModalClose} />
        </div>
    );
};

export default Ubicacion