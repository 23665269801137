function ProgresoBarra({ paso }) { // Recibe el paso como prop
    return (
        <div className="ProgressBarMobile">
            <div className="progress-container-mobile">
                <div 
                    className={`progress-bar-mobile ${paso > 0 ? 'active' : 'inactive'}`} 
                    style={{width: `${((paso + 1) / 3) * 100}%`}}
                ></div>
            </div>
        </div>
    );
}
  
export default ProgresoBarra;
