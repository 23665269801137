import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, BrowserRouter } from "react-router-dom";
import HomePage from './views/HomePage';
import LoginPage from './views/LoginPage';
import DashboardPage from './views/DashboardPage';
import PrivateRoute from './routes/PrivateRoute';
//import NotPRoute from './routes/PublicRoute';
import LandingPage from './views/LandingPage';
import QuienesSomos from './views/QuienesSomos';
import Aliados from './views/Aliados';
import "./components/styles/global.css"
import SolicitarCreditoLanding from './views/SolicitarCreditoLanding';
import SolicitarCredito from './views/SolicitarCredito';
import AvisoPrivacidad from './components/SolicitarCredito/AvisoPrivacidad';
import FacturaDigital from './views/FacturaDigital';
import RegistroAliados from './views/RegistroAliados';
import Empleos from './views/Empleos';
import DetalleEmpleo from './views/DetalleEmpleo';
import Plataforma from './views/Plataforma';
import Login from './views/Login';
import FloatingButton from './components/FloatingButton/FloatingButton';
import EresCliente from './views/EresCliente';

const App = () => {

  return (
    <BrowserRouter>
    <FloatingButton />
      <Routes>
        <Route path="/aliados" element={<Aliados />} />
        <Route path="/registro-aliados" element={<RegistroAliados />} />
        <Route path="/bolsa-trabajo" element={<Empleos />} />
        <Route path="/bolsa-trabajo/:id" element={<DetalleEmpleo />} />
        <Route path="/quienes-somos" element={<QuienesSomos />} />
        <Route path="/home" element={<LandingPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/solicitar" element={<SolicitarCreditoLanding />} />
        <Route path="/solicitar/aviso-privacidad" element={<AvisoPrivacidad />} />
        {/* <Route path="/solicitar-credito" element={<SolicitarCredito />} /> */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/factura" element={<FacturaDigital />} />
        <Route path="/login" element={<Login />} />
        <Route path="/cliente" element={<EresCliente />} />

        {/* rutas privadas, falta meterlas */}
        <Route path="/plataforma/registros" element={
          <PrivateRoute>
            <Plataforma />
          </PrivateRoute>
        } />

        
        <Route path="/dashboard" element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        }/>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
