import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import CardEmpleo from '../components/empleos/CardEmpleo'
import Navbar from '../components/navbar/Navbar'
import TitleSecundario from '../components/titles/TitleSecundario'
import "../components/styles/Empleos.css"

const Empleos = () => {

  const [paso, setPaso] = useState(0); // inicia en el paso 0

  const siguientePaso = () => setPaso(pasoPrevio => pasoPrevio + 1); // función para ir al siguiente paso
  const pasoAnterior = () => setPaso(pasoPrevio => Math.max(pasoPrevio - 1, 0)); // función para ir al paso anterior


  return (
    <>
    <div style={{backgroundImage: 'url(/assets/images/fondoMorado.png)', backgroundSize: '100% 100%', minHeight: '0vh'}}>
       <Navbar/>




    <div className='d-flex justify-content-center align-items-center'>
        <img src='/assets/images/bolsaTrabajo/chamba.svg' className='ps-3 px-3' width="50%"></img>
    </div>

   
    </div>

    <div>
    <TitleSecundario title={"Ofertas Laborales"}/>
    </div>



    <div className='col-12 d-flex flex-column flex-lg-row justify-content-center align-items-center'>
                <div className="unique-search-container d-flex justify-content-center align-items-center col-12 mt-4 mb-4">
                <div className="unique-search-input-group d-flex justify-content-center align-items-center col-lg-2 col-5">
                    <input
                    type="text"
                    className="unique-search-input shadow col-12"
                    placeholder="Todas las ciudades"
                    aria-label="Buscar"
                    />
                    <div className="unique-search-inner-icon-wrap">
                        <i className="fa fa-chevron-down"></i>
                    </div>
                </div>
                <div className="unique-search-input-group d-flex justify-content-center align-items-center col-lg-3 col-5 ps-3">
                    <input
                    type="text"
                    className="unique-search-input shadow col-12"
                    placeholder="Buscar"
                    aria-label="Buscar"
                    />
                    <div className="unique-search-inner-icon-wrap">
                    <span className="unique-search-inner-icon">
                        <i className="fa fa-search"></i>
                    </span>
                    </div>
                </div>
                <div className="unique-search-external-icon-wrap">
                    <span className="unique-search-circle-icon">
                    <i className="fa fa-ellipsis-h"></i>
                    </span>
                </div>
                </div>
    
    
        {/* <InputCiudades/>
        <InputSearch/> */}
    </div>


    <div className='col-12 p-5 p-lg-0 d-flex justify-content-center align-items-center flex-wrap mt-0 mt-lg-5'>
      <CardEmpleo/>
      <CardEmpleo/>
    </div>



    <div></div>


    <div className='p-0 p-lg-5 fondoFooter'>
        <div className="d-flex justify-content-center align-items-center flex-column mt-5" style={{ height: "100%" }}>
            <div className='cuadroFooter camptonLight d-flex flex-column  flex-xxl-row col-12 col-xxl-9'>
                    <div className='d-flex flex-column justify-content-center align-items-start col-xxl-3 col-12 mt-5'>
                        <span className='fs-3 text-start mt-3'>Av. 5 de Mayo No. 2, Col Centro, Mexico City. Mexico</span>
                        <span className='fs-3 text-start mt-3'>+52 55 5237 2000</span>
                        <span className='fs-3 text-start mt-3'>Contacto@tandgo.mx</span>
                    </div>
                    <div className='d-flex flex-column justify-content-center align-items-start ps-3 px-4 col-xxl-3 col-12 mt-5'>
                        <Link to="/home" className='fs-3 text-decoration-none w-100 text-dark mt-2 mb-2' style={{borderBottom: "0.5px solid black", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Home</Link>
                        <Link to="/solicitar" className='fs-3 text-decoration-none w-100 text-dark mt-2 mb-2' style={{borderBottom: "0.5px solid black", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Solicitar crédito</Link>
                        <Link to="/quienes-somos" className='fs-3 text-decoration-none w-100 text-dark mt-2 mb-2' style={{borderBottom: "0.5px solid black", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Quiénes somos</Link>
                        <Link to="/factura" className='fs-3 text-decoration-none w-100 text-dark mt-2 mb-2' style={{borderBottom: "0.5px solid black", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Factura en línea</Link>
                        <Link to="/aliados" className='fs-3 text-decoration-none w-100 text-dark mt-2 mb-2' style={{borderBottom: "0.5px solid black", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Aliados</Link>
                        <Link to="/cliente" className='fs-3 text-decoration-none w-100 text-dark mt-2 mb-2' style={{borderBottom: "0.5px solid black", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>¿Ya eres cliente?</Link>
                        <Link to="/bolsa-trabajo" className='fs-3 text-decoration-none w-100 text-dark mt-2 mb-2' style={{borderBottom: "0.5px solid black", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Bolsa de empleo</Link>
                    </div>
                    <div className='d-flex flex-column justify-content-start align-items-start ps-3 px-4 col-xxl-3 col-12 mt-5'>
                        <Link to="/solicitar/aviso-privacidad" className='fs-3 text-decoration-none w-100 text-dark mt-2 mb-2' style={{borderBottom: "0.5px solid black", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Términos y condiciones</Link>
                        <Link to="/solicitar/aviso-privacidad" className='fs-3 text-decoration-none w-100 text-dark mt-2 mb-2' style={{borderBottom: "0.5px solid black", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Polítias de privacidad</Link>
                        <Link to="/cat" className='fs-3 text-decoration-none w-100 text-dark mt-2 mb-2' style={{borderBottom: "0.5px solid black", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>CAT</Link>
                        <Link to="/peticion-formal" className='fs-3 text-decoration-none w-100 text-dark mt-2 mb-2' style={{borderBottom: "0.5px solid black", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Hacer una petición formal</Link>
                    </div>
                    <div className='d-flex flex-column justify-content-start align-items-center col-xxl-4 col-12 mt-5 mt-lg-5'>
                        <span className='fs-3 text-center'>Registra tu email y recibe promociones al instante</span>
                        <input className='fs-3 inputFooter mt-3' placeholder='Escribe...'></input>
                        <button className='custom-button mt-3 ps-2 px-2 w-auto fs-3 w-auto d-flex justify-content-center alig-items-center'> <span>Suscribir</span> </button>
                        <span className='fs-3 mt-3 mb-3'>Nuestras redes sociales</span>
                        <div className='iconosFooter mt-5 d-flex justify-content-center align-items-center mt-4'>
                            <img src='/assets/images/home/what.png' className='ps-3 px-3' width="20%"></img>
                            <img src='/assets/images/home/face.png' className='ps-3 px-3' width="20%"></img>
                            <img src='/assets/images/home/insta.png' className='ps-3 px-3' width="20%"></img>
                            <img src='/assets/images/home/yt.png' className='ps-3 px-3' width="20%"></img>
                        </div>
                    </div>
                </div>
            <img src='/assets/images/home/Footer2.png' style={{ maxWidth: "100%", height: "50%" }} className="imgFooter d-none d-xxl-flex "/>
            </div>
    </div>
    </>
  )
}

export default Empleos;