import React, { useState, useEffect } from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import { solicitudCliente, getHours, creditRequestsResource } from "../../../../helpers/backend_helper"
import Calendario from '../../../calendario/Calendario';

const Horario = ({ avanzar, solicitud, setSolicitud }) => {
    const [times, setTimes] = useState([]);
    useEffect(() => {
        const getTimes = async () => {
            const response = await getHours({ params: { register: true } })
            setTimes(response.hours)
        }
        getTimes();
    }, []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: solicitud,
        validationSchema: Yup.object({
            name: Yup.string().required('Direccion es requerida'),
            phone: Yup.string().required('Colonia o delegacion es obligatorio'),
            amount: Yup.string().required('Monto de credito '),
        }),
        onSubmit: async values => {
            await setSolicitud(values)
            creditRequestsResource('post', values)
            avanzar()
        },
    });

    return (
        <div>

            <div></div>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <span className='camptonLight text-start col-12 mb-4 mt-0 d-flex justify-content-center fs-20'>
                    Registra los datos del cliente
                </span>


                <div>
                    <div className='d-flex col-12 justify-content-center ps-5 ps-md-5 ps-lg-5 ps-xxl-5 px-0 px-md-5 px-lg-5 px-xxl-5 p-0 p-md-3 p-lg-3 p-xxl-3 flex-column flex-md-row '>
                        <div className='d-flex flex-column col-10 col-md-6 mt-3 mt-md-0'>
                            <label className='fw-bold camptonLight fs-15'>Numero de teléfono del cliente</label>
                            <input
                                name="phone"
                                placeholder='Numero de telefono'
                                className={`inputContactanos camptonLight ${validation.touched.phone && validation.errors.phone ? 'has-error' : ''}`}
                                onChange={(e) => {
                                    validation.handleChange(e);
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                            />
                            {validation.touched.phone && validation.errors.phone && (
                                <div className='error-text' style={{ color: 'red' }}>{validation.errors.phone}</div>
                            )}
                        </div>

                    </div>
                    <div className='d-flex col-12 justify-content-center ps-5 ps-md-5 ps-lg-5 ps-xxl-5 px-0 px-md-5 px-lg-5 px-xxl-5 p-0 p-md-3 p-lg-3 p-xxl-3 flex-column flex-md-row '>
                        <div className='d-flex flex-column col-10 col-md-6 mt-3 mt-md-0'>
                            <label className='fw-bold camptonLight fs-15'>Nombres y apellidos del cliente</label>
                            <input
                                name="name"
                                placeholder='Nombre'
                                className={`inputContactanos camptonLight ${validation.touched.name && validation.errors.name ? 'has-error' : ''}`}
                                onChange={(e) => {
                                    validation.handleChange(e);
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                            />
                            {validation.touched.name && validation.errors.name && (
                                <div className='error-text' style={{ color: 'red' }}>{validation.errors.name}</div>
                            )}
                        </div>
                        <div className='d-flex flex-column col-10 col-md-6 ps-0 ps-md-4  mt-3 mt-md-0'>
                            <label className='fw-bold camptonLight fs-15'>Monto de crédito</label>
                            <input
                                name="amount"
                                placeholder='Monto de credito'
                                className={`inputContactanos camptonLight ${validation.touched.amount && validation.errors.amount ? 'has-error' : ''}`}
                                onChange={(e) => {
                                    validation.handleChange(e);
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.amount || ""}
                            />
                            {validation.touched.amount && validation.errors.amount && (
                                <div className='error-text' style={{ color: 'red' }}>{validation.errors.amount}</div>
                            )}
                        </div>
                    </div>
                </div>





                <div className='d-flex col-12 justifu-content-between align-items-center'>
                    <div className='d-flex flex-column align-items-center justify-content-center col-6 mb-5'>
                        <span className='camptonLight text-start col-10 mb-2 mt-3 d-flex justify-content-center fs-15'>
                            Selecciona la franja horaria en la que el cliente está usualmente en su negocio.
                        </span>
                        <Calendario hours={times} setClient={setSolicitud} client={solicitud} dateInput={false} />
                    </div>


                    <div className='d-flex col-12'>
                        <div className='col-4 d-flex align-items-center bg-comision'>
                            <div className='col-3 card border-0 shadow d-flex justify-content-center align-items-center col-2 cardComision'>
                                <img src='/assets/images/plataforma/money.svg' className='mt-5 mb-5 col-5 d-flex justify-content-center align-items-center' alt='Opción 1'></img>
                            </div>
                            <div className='text-center col-9 d-flex flex-column text-center justify-content-center align-items-center'>
                                <span className='camptonLight fw-bold fs-20'>Tu posible comisión</span>
                                <span className='camptonBold fs-25'>$0</span>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='col-12 justify-content-center align-items-center d-flex mb-5'>
                    <button type='submit' className='me-3 w-auto custom-button-primary shadow cardRounded camptonLight d-flex flex-column justify-content-center align-items-center'>
                        <span className='camptonLight ps-3 px-3 fs-15'>Registrar cliente</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default Horario