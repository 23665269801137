import React from 'react'
import { Modal } from 'react-bootstrap';
import '../../styles/modales.css';

const AvisoCasiListo = ({isOpen, onClose}) => {
  return (
    <Modal show={isOpen} onHide={onClose} centered size="lg">
        <Modal.Header className='d-flex flex-column border-0'>
            <img 
                style={{width: '40%', height: 'auto', marginBottom: "-100px", marginTop: "-143px"}} 
                src="/assets/images/solicitarCredito/casiListo.png" 
                alt="Imagen de éxito" 
            />
            <h1 className='camptonBold fs-40'>¡Estás por terminar tu solicitud!</h1>
        </Modal.Header>
        <div className="content-container">
            <p className='text-center fs-20 camptonLight fw-bold'>Necesitamos saber un poco mas de ti para brindarte una visita a tu medida</p>
        </div>
        <Modal.Footer>
            <button className="custom-button-primary w-auto p-3 d-flex justify-content-center align-items-center  camptonLight fw-bold" onClick={onClose}>
                Continuar
            </button>
        </Modal.Footer>
    </Modal>
)
}

export default AvisoCasiListo