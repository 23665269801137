import React, { useState, useRef } from 'react';
import { StreetViewPanorama, GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import html2canvas from 'html2canvas';

const Map = ({ solicitud, setSolicitud, streetViewMap }) => {
    const [capturedImage, setCapturedImage] = useState(null);
    const handleMapClick = (e) => {
        console.log(e)
        setSolicitud({...solicitud,lng:e.latLng.lng(),lat:e.latLng.lat()})
    };
    const streetViewOptions = {
        position: { lat: solicitud.lat, lng: solicitud.lng }, // Coordenadas de ejemplo (Nueva York)
        pov: { heading: 100, pitch: 10 }, // Orientación y ángulo de inclinación del Street View
        visible: true, // Hace que el Street View sea visible
        enableCloseButton: true, // Muestra el botón para cerrar el Street View
    };

    return (
        <LoadScript googleMapsApiKey="AIzaSyBd2Yji4ITZox7e1giZIWQ5pIyJvfJ2aTQ">
            <GoogleMap
                center={streetViewOptions.position}
                zoom={16}
                mapContainerStyle={{ width: '100%', height: '600px' }}
                onClick={handleMapClick}
            >
                {streetViewMap ?
                    <StreetViewPanorama options={streetViewOptions}/>
                    :
                    <Marker
                        position={streetViewOptions.position}
                    />
                }

            </GoogleMap>
        </LoadScript>
    );
};

export default Map;
