import React, { useState,useEffect } from 'react'
import * as Yup from "yup";
import { useFormik } from "formik";
import { contactanos } from '../../helpers/backend_helper';
import Select from 'react-select'
import classnames from "classnames";
import { useNavigate } from 'react-router-dom';

const Contactanos = () => {
    const navigate = useNavigate()
    const [asuntos,setAsunto] = useState([
        {id:1,name:'Queja'},
        {id:1,name:'Solicitud de informacion'},
        {id:1,name:'Agradecimiento'},
        {id:1,name:'Ayuda'},
    ])
    const [contact, setContact] = useState({
        name: '',
        phone: '',
        surnames: '',
        message: '',
        email: '',
        asunto_id:null,
        area:''
    });
    
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: contact,
        validationSchema: Yup.object({
            name: Yup.string().required('El nombre es obligatorio'),
            phone: Yup.string().required('El número telefónico es obligatorio'),
            surnames: Yup.string().required('El género es obligatorio'),
            email: Yup.string().required('El email es obligatorio'),
            asunto_id: Yup.string().required('El asunto es obligatorio'),
            area: Yup.string().required('El area es obligatorio'),
        }),
        onSubmit: async values => {
            console.log(values,'valuesss')
            const response = await contactanos(values);
            console.log(response)
            if (response.status) {
                navigate('/')
            } else {
                
            }
        },
    });

    return (
        <div style={{ backgroundImage: 'url(/assets/images/QuienesSomos/customer.png)', backgroundSize: 'cover', height: '100%' }}>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    console.log(validation);
                    return false;
                }}
            >
                <div className='col-12 d-flex justify-content-center align-items-center p-2 p-lg-5'>
                    <div style={{ borderRadius: "20px" }} className='card card d-flex justify-content-center align-items-center col-md-7 col-lg-7 col-xxl-7 col-12'>
                        <div className='mt-4 d-flex flex-row justify-content-center alig-items-center'>
                            <img src='assets/images/contactanos/contactanos.svg' className='col-2 px-1'></img>
                            <span className='fw-bold fs-30 camptonMedium spacing-1 ps-3'>Contáctanos</span>
                        </div>
                        <div className='col-10'>
                            <span className=' fs-25 text-center col-12 d-flex mt-3  camptonLight spacing-1' style={{ fontWeight: "700" }}>Estamos para ayudarte, déjanos tus datos y requerimientos y nos pondremos en contacto.</span>
                        </div>
                        <div className='d-flex col-12 justify-content-center ps-5 ps-md-5 ps-lg-5 ps-xxl-5 px-0 px-md-5 px-lg-5 px-xxl-5 p-0 p-md-3 p-lg-3 p-xxl-3 flex-column flex-md-row '>
                            <div className='d-flex flex-column col-10 col-md-6 mt-3 mt-md-0'>
                                <label className='fw-bold camptonLight spacing-1'>Nombres</label>
                                <input
                                    name="name"
                                    placeholder='Escribe tu nombre'
                                    className={`inputContactanos camptonLight ${validation.touched.name && validation.errors.name ? 'has-error' : ''}`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.name || ""}
                                />
                                {validation.touched.name && validation.errors.name && (
                                    <div className='error-text' style={{ color: 'red' }}>{validation.errors.name}</div>
                                )}
                            </div>
                            <div className='d-flex flex-column col-10 col-md-6 ps-0 ps-md-4  mt-3 mt-md-0'>
                                <label className='fw-bold camptonLight spacing-1'>Apellidos</label>
                                <input
                                    name="surnames"
                                    placeholder='Escribe tus apellidos'
                                    className={`inputContactanos camptonLight ${validation.touched.surnames && validation.errors.surnames ? 'has-error' : ''}`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.surnames || ""}
                                />
                                {validation.touched.surnames && validation.errors.surnames && (
                                    <div className='error-text' style={{ color: 'red' }}>{validation.errors.surnames}</div>
                                )}
                            </div>
                        </div>
                        <div className='d-flex col-12 justify-content-center ps-5 ps-md-5 ps-lg-5 ps-xxl-5 px-0 px-md-5 px-lg-5 px-xxl-5 p-0 p-md-3 p-lg-3 p-xxl-3 flex-column flex-md-row '>
                            <div className='d-flex flex-column col-10 col-md-6  mt-3 mt-md-0'>
                                <label className='fw-bold camptonLight spacing-1'>Teléfono</label>
                                <input
                                    name="phone"
                                    placeholder='Escribe tu numero telefonico'
                                    className={`inputContactanos camptonLight ${validation.touched.phone && validation.errors.phone ? 'has-error' : ''}`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.phone || ""}
                                />
                                {validation.touched.phone && validation.errors.phone && (
                                    <div className='error-text' style={{ color: 'red' }}>{validation.errors.phone}</div>
                                )}
                            </div>
                            <div className='d-flex flex-column col-10 col-md-6 ps-0 ps-md-4  mt-3 mt-md-0'>
                                <label className='fw-bold camptonLight spacing-1'>Email</label>
                                <input
                                    name="email"
                                    placeholder='Escribe tu email'
                                    className={`inputContactanos camptonLight ${validation.touched.email && validation.errors.email ? 'has-error' : ''}`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                />
                                {validation.touched.email && validation.errors.email && (
                                    <div className='error-text' style={{ color: 'red' }}>{validation.errors.email}</div>
                                )}
                            </div>
                        </div>
                        <div className='d-flex col-12 justify-content-center ps-5 ps-md-5 ps-lg-5 ps-xxl-5 px-0 px-md-5 px-lg-5 px-xxl-5 p-0 p-md-3 p-lg-3 p-xxl-3 flex-column flex-md-row '>
                            <div className='d-flex flex-column col-10 col-md-6  mt-3 mt-md-0'>
                                <label className='fw-bold camptonLight spacing-1'>Asunto</label>
                                <Select
									className={classnames({'is-invalid': (validation.touched.asunto_id && validation.errors.asunto_id)})}
									classNamePrefix="select"
									isClearable={true}
									value={ asuntos.filter(e => e.id == validation.values.asunto_id)[0] ?? null }
									isSearchable={true}
									getOptionLabel={(option) => `${option.name}`}
									getOptionValue={(option) => `${option.id}`}
									name="asunto_id"
									options={asuntos}
									onChange={(option, meta) => {validation.setFieldValue('asunto_id', option.id, true);validation.setFieldValue('asunto_nombre', option.name, true)}}
									invalid={(validation.touched.asunto_id && validation.errors.asunto_id)}
								/>
                                {validation.touched.asunto_id && validation.errors.asunto_id && (
                                    <div className='error-text' style={{ color: 'red' }}>{validation.errors.asunto_id}</div>
                                )}
                            </div>
                            <div className='d-flex flex-column col-10 col-md-6 ps-0 ps-md-4  mt-3 mt-md-0'>
                                <label className='fw-bold camptonLight spacing-1'>Área que quieras dirigir tu consulta</label>
                                <input
                                    name="area"
                                    placeholder='Escribe el área. Ejemplo: contaduría'
                                    className={`inputContactanos camptonLight ${validation.touched.area && validation.errors.area ? 'has-error' : ''}`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.area || ""}
                                />
                                {validation.touched.area && validation.errors.area && (
                                    <div className='error-text' style={{ color: 'red' }}>{validation.errors.area}</div>
                                )}
                            </div>
                        </div>

                       
                        <div className='mb-4 mt-3'>
                            <button type='submit' className='custom-button-primary w-auto ps-3 px-3 camptonLight fw-bold'>Enviar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Contactanos