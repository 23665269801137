import React from 'react'
import { Link } from 'react-router-dom'
import AliadoTandgo from '../components/aliados/AliadoTandgo'
import OpcionesAliados from '../components/aliados/OpcionesAliados'
import RequisitosCard from '../components/aliados/RequisitosCard'
import InputNewsletter from '../components/formulario/InputNewsletter'
import Navbar from '../components/navbar/Navbar'
import SubtitlePrimario from '../components/titles/SubtitlePrimario'
import TitleSecundario from '../components/titles/TitleSecundario'
import TitleTercero from '../components/titles/TitleTercero'

const Aliados = () => {
  return (
    <>
    <div style={{backgroundImage: 'url(/assets/images/fondoMorado.png)', backgroundSize: '100% 100%', minHeight: '40em', maxHeight: "100em", height: '50em'}}>
       <Navbar/>
       <AliadoTandgo/>
    </div>

  <div>
    <TitleSecundario title={"¿Cómo ser un aliado Tandgo?"}/>
    <SubtitlePrimario subtitle={"Para ser un aliado solo necesitas registrar la siguiente información:"}/>
  </div>

  <div className='mb-5 col-12'>
    <RequisitosCard/>
  </div>

  <div className='mb-5 col-12 d-flex justify-content-end align-items-end' style={{marginTop: "-130px"}}>
    <img className='imgFondoNaranja d-flex justify-content-end align-items-end' src='assets/images/aliados/fondoNaranja.svg'></img>
  </div>

   </>

  )
}

export default Aliados