import React,{useEffect,useState} from 'react'
import GoogleMapReact from 'google-map-react';
import * as Yup from "yup";
import { useFormik } from "formik";
import Map from '../../../maps/Map'

const Mapa = ({ avanzar, solicitud, setSolicitud }) => {
    const [codigoPostal, setCodigoPostal] = useState('');
    const [ubicacion, setUbicacion] = useState({
        lat: 19.475994,
        lng: -99.171411,
    });
    const mapOptions = {
        center: {
            lat: 19.475994,
            lng: -99.171411,
        },
        zoom: 15,
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: solicitud,
        validationSchema: Yup.object({
            address: Yup.string().required('Direccion es requerida'),
            delegation: Yup.string().required('Colonia o delegacion es obligatorio'),
            postal_code: Yup.string().required('El codigo postal es obligatorio'),
            city: Yup.string().required('La ciudad es obligatoria'),
            aditional_references: Yup.string().required('La informacion adicional es obligatorio'),
            between_streets: Yup.string().required('La entre calles es obligatorio'),
            floor_number: Yup.string().required('La exterior es obligatorio'),
            state: Yup.string().required('El estado es obligatorio'),
        }),
        onSubmit: async values => {
            values.lat=ubicacion.lat;
            values.lng=ubicacion.lng;
            setSolicitud(values)
            avanzar()
        },
    });

      const buscarUbicacionEnGoogleMaps = async () => {
        try {
          // Construir la dirección para la búsqueda en Google Maps
          
          const direccion = `${validation.values.address}, ${validation.values.floor_number}, ${validation.values.delegation}, ${validation.values.city}, ${validation.values.state}, ${validation.values.country}, ${validation.values.postal_code}`;
          console.log(direccion,'direccion')
          // Hacer la solicitud a la API de geocodificación de Google Maps
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
              direccion
            )}&key=AIzaSyBd2Yji4ITZox7e1giZIWQ5pIyJvfJ2aTQ`
          );
            
          const data = await response.json();
            console.log(data)
          if (data.results.length > 0) {
            const ubicacion = data.results[0].geometry.location;
            setUbicacion({ lat: ubicacion.lat, lng: ubicacion.lng });
            solicitud.lat=ubicacion.lat;
            solicitud.lng=ubicacion.lng;
            return { lat: ubicacion.lat, lng: ubicacion.lng };
          } else {
            console.error('No se pudo encontrar la ubicación para la dirección:', direccion);
            return { lat: 0, lng: 0 };
          }
        } catch (error) {
          console.error('Error al buscar la ubicación:', error);
          return { lat: 0, lng: 0 };
        }
      };

    return (
        <div className='border-0 col-12 px-2'>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <div className=' border-0 p-2 d-flex flex-row col-12'>
                    <div className="d-flex flex-column justify-content-center shadow borderMapa align-items-center col-4">
                        <span className='camptonLight p-3 fs-15'>Si prefieres, escribe la dirección del cliente y la ubicación en el mapa por ti.</span>
                        <div className='d-flex flex-column col-10 col-md-10 mt-3 mt-md-0'>
                            <label className='fw-bold camptonLight fs-15'>Direccion</label>

                            <input
                                name="address"
                                placeholder='Escribe la direccion'
                                className={`inputContactanos camptonLight ${validation.touched.address && validation.errors.address ? 'has-error' : ''}`}
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    buscarUbicacionEnGoogleMaps();
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.address || ""}
                            />
                            {validation.touched.address && validation.errors.address && (
                                <div className='error-text' style={{ color: 'red' }}>{validation.errors.address}</div>
                            )}

                        </div>
                        <div className='d-flex flex-column col-10 col-md-10 mt-3 mt-md-2'>
                            <label className='fw-bold camptonLight fs-15 mt-1'>Colonia/Delegación</label>
                            <input
                                name="delegation"
                                placeholder='Escribe la delegación'
                                className={`inputContactanos camptonLight ${validation.touched.delegation && validation.errors.delegation ? 'has-error' : ''}`}
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    buscarUbicacionEnGoogleMaps();
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.delegation || ""}
                            />
                            {validation.touched.delegation && validation.errors.delegation && (
                                <div className='error-text' style={{ color: 'red' }}>{validation.errors.delegation}</div>
                            )}
                        </div>
                        <div className='d-flex flex-column col-10 col-md-10 mt-3 mt-md-2'>
                            <label className='fw-bold camptonLight fs-15 mt-1'>Código Postal</label>
                            <input
                                name="postal_code"
                                placeholder='Escribe codigo postal'
                                className={`inputContactanos camptonLight ${validation.touched.postal_code && validation.errors.postal_code ? 'has-error' : ''}`}
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    buscarUbicacionEnGoogleMaps();
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.postal_code || ""}
                            />
                            {validation.touched.postal_code && validation.errors.postal_code && (
                                <div className='error-text' style={{ color: 'red' }}>{validation.errors.postal_code}</div>
                            )}
                        </div>
                        <div className='d-flex flex-column col-10 col-md-10 mt-3 mt-md-2'>
                            <label className='fw-bold camptonLight fs-15 mt-1'>Ciudad</label>
                            <input
                                name="city"
                                placeholder='Escribe la ciudad'
                                className={`inputContactanos camptonLight ${validation.touched.city && validation.errors.city ? 'has-error' : ''}`}
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    buscarUbicacionEnGoogleMaps();
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.city || ""}
                            />
                            {validation.touched.city && validation.errors.city && (
                                <div className='error-text' style={{ color: 'red' }}>{validation.errors.city}</div>
                            )}
                        </div>
                        <div className='d-flex flex-column col-10 col-md-10 mt-3 mt-md-2'>
                            <label className='fw-bold camptonLight fs-15 mt-1'>Estado</label>
                            <input
                                name="state"
                                placeholder='Escribe el estado'
                                className={`inputContactanos camptonLight ${validation.touched.state && validation.errors.state ? 'has-error' : ''}`}
                                onChange={(e) => {
                                    validation.handleChange(e);
                                    buscarUbicacionEnGoogleMaps();
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.state || ""}
                            />
                            {validation.touched.state && validation.errors.state && (
                                <div className='error-text' style={{ color: 'red' }}>{validation.errors.state}</div>
                            )}
                        </div>
                        <div className='d-flex flex-column col-10 col-md-10 mt-3 mt-md-2'>
                            <label className='fw-bold camptonLight fs-15 mt-1'>Información adicional</label>
                            <input
                                name="aditional_references"
                                placeholder='Escribe la información adicional'
                                className={`inputContactanos camptonLight ${validation.touched.aditional_references && validation.errors.aditional_references ? 'has-error' : ''}`}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.aditional_references || ""}
                            />
                            {validation.touched.aditional_references && validation.errors.aditional_references && (
                                <div className='error-text' style={{ color: 'red' }}>{validation.errors.aditional_references}</div>
                            )}
                        </div>
                        <div className='d-flex col-10'>
                            <div className='d-flex flex-column col-10 col-md-5 me-4 mt-3 mt-md-2'>
                                <label className='fw-bold camptonLight fs-15 mt-1'>Entrecalles</label>
                                <input
                                    name="between_streets"
                                    placeholder='Escribe entre que calles se ubíca'
                                    className={`inputContactanos camptonLight ${validation.touched.between_streets && validation.errors.between_streets ? 'has-error' : ''}`}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.between_streets || ""}
                                />
                                {validation.touched.between_streets && validation.errors.between_streets && (
                                    <div className='error-text' style={{ color: 'red' }}>{validation.errors.between_streets}</div>
                                )}
                            </div>
                            <div className='d-flex flex-column col-10 col-md-5 mt-3 mt-md-2 mb-2'>
                                <label className='fw-bold camptonLight fs-15 mt-1'>Exterior</label>
                                <input
                                    name="floor_number"
                                    placeholder='Escribe tu Numero exterior'
                                    className={`inputContactanos camptonLight ${validation.touched.floor_number && validation.errors.floor_number ? 'has-error' : ''}`}
                                    onChange={(e) => {
                                        validation.handleChange(e);
                                        buscarUbicacionEnGoogleMaps();
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.floor_number || ""}
                                />
                                {validation.touched.floor_number && validation.errors.floor_number && (
                                    <div className='error-text' style={{ color: 'red' }}>{validation.errors.floor_number}</div>
                                )}
                            </div>
                        </div>
                        <button
                            type='submit'
                            className='mt-3 mb-3 me-3 col-6 custom-button-primary shadow cardRounded camptonLight d-flex flex-column justify-content-center align-items-center'
                        >
                            <span className='camptonLight ps-3 px-3 fs-20'>Confirmar</span>
                        </button>
                    </div>
                    <div className='col-8'>
                        <div style={{ height: '600px', width: '100%', border: "0" }}>
                            <Map solicitud={ubicacion} setSolicitud={setUbicacion} streetViewMap={false}/>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default Mapa