import React, { useState, useEffect } from 'react';
import Progreso from '../../lineaProgreso/Progreso';
import ProgresoBarra from '../../lineaProgreso/ProgresoBarra';
import Opciones from '../registros/Opciones';
import Fachada from './frenteNegocio/Fachada';
import Horario from './frenteNegocio/Horario';
import RegistroAprobado from './frenteNegocio/RegistroAprobado';
import TarjetaPagos from './frenteNegocio/TarjetaPagos';
import Ubicacion from './frenteNegocio/Ubicacion';
import CargaImagenes from './lejosNegocio/CargaImagenes';
import CargoImagenCorrecto from './lejosNegocio/CargoImagenCorrecto';
import HorarioLejos from './lejosNegocio/HorarioLejos';
import RegistroAprobadoLejos from './lejosNegocio/RegistroAprobadoLejos';
import StreetView from './lejosNegocio/StreetView';
import UbicacionLejos from './lejosNegocio/UbicacionLejos';


const NuevoRegistro = () => {


  const [opcion, setOpcion] = useState(null);
  const [paso, setPaso] = useState(0);
  const [esMovil, setEsMovil] = useState(window.matchMedia("(max-width: 767px)").matches);
  const [imagen, setImagen] = useState(null);


  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    const handleResize = () => setEsMovil(mediaQuery.matches);

    mediaQuery.addListener(handleResize);
    return () => mediaQuery.removeListener(handleResize);
  }, []);

  const seleccionarOpcion = (opcionSeleccionada) => {
    setOpcion(opcionSeleccionada);
    setPaso(1);
  };

  const avanzar = () => {
    setPaso((prevPaso) => prevPaso + 1);
  };


  const avanzarYGuardarImagen = (img) => {
    setImagen(img);
    avanzar();
  };


  const retroceder = (newFile) => {
    setImagen(newFile);
    setPaso(paso - 1);
  };



  const renderizarContenidoPorOpcion = () => {
    switch (opcion) {
      case 'frente':
        return <ContenidoOpcion1 paso={paso} avanzar={avanzar} />; // Pasar avanzar como prop
      case 'lejos':
        return <ContenidoOpcion2 setImagen={setImagen} paso={paso} avanzar={avanzar} avanzarYGuardarImagen={avanzarYGuardarImagen} imagen={imagen} retroceder={retroceder} />; // Pasar avanzar como prop
      default:
        return null;
    }
  };

  return (
    <div>
      {opcion ? (esMovil ? <ProgresoBarra paso={paso} /> : <Progreso paso={paso} text={"rgb(172, 172, 172)"} />) : null}
      {opcion ? (
        <div>
          {renderizarContenidoPorOpcion()}
        </div>
      ) : (
        <Opciones seleccionarOpcion={seleccionarOpcion} />
      )}
    </div>
  );
};

const ContenidoOpcion1 = ({ paso, avanzar }) => { // Recibir avanzar como prop
  const [solicitud, setSolicitud] = useState({
    address: '',
    floor_number: '',
    between_streets: '',
    country: 'Mexico',
    state: '',
    postal_code: '',
    aditional_references: '',
    delegation: '',
    city: '',
    phone: '',
    name: '',
    email: '',
    amount: '',
    working_hours_id: null,
    newClient:true,
    lat:0,
    lng:0
  })

  switch (paso) {
    case 1:
      return <Ubicacion solicitud={solicitud} setSolicitud={setSolicitud} avanzar={avanzar} showMapa={false}/>; // Pasar avanzar como prop
    case 2:
      return <Fachada solicitud={solicitud} setSolicitud={setSolicitud} avanzar={avanzar} />;
    case 3:
      return <TarjetaPagos solicitud={solicitud} setSolicitud={setSolicitud} avanzar={avanzar} />;
    case 4:
      return <Horario solicitud={solicitud} setSolicitud={setSolicitud} avanzar={avanzar} />;
    case 5:
      return <RegistroAprobado solicitud={solicitud} setSolicitud={setSolicitud} avanzar={avanzar} />;
    default:
      return null;
  }
};

const ContenidoOpcion2 = ({ paso, avanzar, avanzarYGuardarImagen, imagen, retroceder, setImagen }) => { // Recibir avanzar como prop

  const [solicitud, setSolicitud] = useState({
    address: '',
    floor_number: '',
    between_streets: '',
    country: 'Mexico',
    state: '',
    postal_code: '',
    aditional_references: '',
    delegation: '',
    city: '',
    phone: '',
    name: '',
    email: '',
    amount: '',
    working_hours_id: null,
    newClient:true,
    lat:19.475994,
    lng:-99.171411
  })

  switch (paso) {
    case 1:
      return <Ubicacion solicitud={solicitud} setSolicitud={setSolicitud} avanzar={avanzar} showMapa={true} />;
    case 2:
      return <StreetView solicitud={solicitud} setSolicitud={setSolicitud} avanzar={avanzar} />;
    case 3:
      return <CargaImagenes solicitud={solicitud} setSolicitud={setSolicitud} avanzar={avanzarYGuardarImagen} setArchivo={retroceder} />;
    case 4:
      return <CargoImagenCorrecto solicitud={solicitud} setSolicitud={setSolicitud} imagen={imagen} avanzar={avanzar} retroceder={retroceder} setImagen={setImagen} />;
    case 5:
      return <Horario solicitud={solicitud} setSolicitud={setSolicitud} avanzar={avanzar} />;
    case 6:
      return <RegistroAprobadoLejos solicitud={solicitud} setSolicitud={setSolicitud} avanzar={avanzar} />;

    default:
      return null;
  }
};

export default NuevoRegistro;
