import React from 'react'

const Confianza = () => {
    return (
        <>
        <div className='d-flex justify-content-center flex-column flex-lg-row flex-md-column col-12' style={{marginTop: "-40px"}} >
            <div className='mt-0 d-flex justify-content-center lado-izquierdo'  style={{backgroundImage: 'url(/assets/images/QuienesSomos/fondoMorado.png)', backgroundSize: 'cover', height: '100%'}}>
                <img src='/assets/images/aliados/unidos.png' className='img-custom-quienes'/>
            </div>
            <div className='px-2 px-lg-5 flex-column d-flex justify-content-center'>
            <div className='d-flex flex-column justify-content-center align-items-end text-white fw-bold w-100 px-1 px-lg-5'>
                <h1 className="text-end mt-4 mb-5 color-primary fw-bold border-primary-home p-3">
                    <span className="p-3 camptonBold">
                        Confianza
                    </span>
                </h1>
                <span className="col-10 col-md-10 col-lg-10 fs-30 text-black text-end camptonLight fw-bold">Descripción corta de la historia, misión, visión o por qué es reconocida Tandgo comparado con la competencia.</span>
            </div>
            </div>
        </div>
        </>
    )
}

export default Confianza
