import React from 'react'

const CuadrosPrincipales = ({title1, subtitle1,title2, subtitle2,title3, subtitle3,title4, subtitle4}) => {
  return (
        <div className='espaciadoComisionesPrincipales'>
        <div className='col-12 col-lg-9 col-xxl-12  d-flex flex-column flex-lg-row justify-content-between align-items-between'>
            <div className='card shadowCuadroPincipal borderRoundedPrincipales col-12 m-3 col-lg-3  p-3 d-flex justify-content-center align-items-center'>
                <span className='camptonLight fs-15 text-center col-12'>{title1} </span>
                <span className='camptonMedium fs-20'>{subtitle1} </span>
            </div>
            <div className='card shadowCuadroPincipal borderRoundedPrincipales col-12 m-3 col-lg-3  d-flex justify-content-center align-items-center'>
                <span className='camptonLight fs-15 text-center col-12'>{title2}</span>
                <span className='camptonMedium fs-20'>{subtitle2}</span>
            </div>
            <div className='card shadowCuadroPincipal borderRoundedPrincipales col-12 m-3 col-lg-3  p-3 d-flex justify-content-center align-items-center'>
                <span className='camptonLight fs-15 text-center col-12'>{title3}</span>
                <span className='camptonMedium fs-20'>{subtitle3}</span>
            </div>
            <div className='card shadowCuadroPincipal borderRoundedPrincipales col-12 m-3 col-lg-3 p-3 d-flex justify-content-center align-items-center'>
                <span className='camptonLight fs-15 text-center col-12'>{title4}</span>
                <span className='camptonMedium fs-20'>{subtitle4}</span>
            </div>
        </div>
        </div>
  )
}

export default CuadrosPrincipales